import { useMemo } from 'react';

// utilities
import { getEnv } from 'utils/env';
import { isRedirectedFromFUE } from 'utils/isRedirectedFromFUE';
import { useResponsive } from 'hooks';
import { TrackEvents } from 'services';

// styles
import styles from './index.module.scss';

const GoBackToDashboardCta = () => {
	const { isMobile } = useResponsive();

	const redirectedFromFUE = isRedirectedFromFUE();

	const isCtaVisible = useMemo(
		() => isMobile && redirectedFromFUE,
		[isMobile, redirectedFromFUE],
	);

	const onCtaClick = () => {
		TrackEvents.log('button_click', {
			button_name: 'go_back_to_dashboard',
		});
		window.location.href = getEnv('DASHBOARD_URL');
	};

	return isCtaVisible ? (
		<button className={styles.goBackToDashboardCta} onClick={onCtaClick}>
			Go Back to Dashboard
		</button>
	) : null;
};

export default GoBackToDashboardCta;
