import { createStore, createTypedHooks } from 'easy-peasy';

import user, { UserStore } from './user';
import notification, { NotificationStore } from './notification';
import learnerController, {
	LearnerControllerStore,
} from './learner-controller';
import jobs, { JobsStore } from './jobs';
import courses, { CourseStore } from './courses';

export interface StoreModel {
	user: UserStore;
	notification: NotificationStore;
	learnerController: LearnerControllerStore;
	jobs: JobsStore;
	courses: CourseStore;
}

const typedHooks = createTypedHooks<StoreModel>();
const { useStoreActions, useStoreDispatch, useStoreState } = typedHooks;
export { useStoreActions, useStoreDispatch, useStoreState };

export default createStore<StoreModel>(
	{
		user,
		notification,
		learnerController,
		jobs,
		courses,
	},
	{
		name: 'upGrad Careers',
		devTools:
			!!localStorage.getItem('redux-devTools') ||
			process.env.NODE_ENV !== 'production',
	},
);
