export default Object.freeze({
	pageSize: 10,
	daysForJobToBeNew: 2,
	shouldShowOnboarding: 'shouldShowJobOnboarding',
	salaryCurrencySymbol: '₹',
	salaryUnit: 'LPA',
	noResumeNoProfileError:
		'You cannot apply to Jobs as your Profile and Resume is not updated. Please update your Profile and Resume.',
	noResumeError:
		'You cannot apply to Jobs as your Resume is not updated. Please update your Resume.',
	noProfileError:
		'You cannot apply to Jobs as your Profile is not updated. Please update your Profile.',
	externalJobLinkMissingError:
		'This is an off-platform job and the URL is missing',
	searchPlaceholder: 'Search Job titles, company or keyword',
	htmlEntityOfComma: '*&#44;',
	bannerText: 'Want to explore great careers with upGrad? Enroll now',
});
