import platform from 'platform';
import { UserLeadInfo } from 'types/auth-services';
import { getCookie } from 'utils/cookies';
import { cookies, routes } from '../constants';
import Logger from './logger';

const logger = new Logger({ fileName: 'trackevents' });

declare global {
	interface Window {
		clevertap: {
			event: Array<any>;
			profile: Array<{
				Site: {
					Name: string;
					Email: string;
				};
			}>;
			account: Array<{
				id: string;
			}>;
			onUserLogin: Array<any>;
			notifications: Array<any>;
			privacy: Array<any>;
		};
		__insp: {
			push: (t: [string, string | object]) => void;
		};
	}
}

interface BaseProperties {
	user_id?: number;
	email?: string;
	devicetype?: 'mWeb' | 'dWeb';
	width?: string;
	height?: string;
	browser?: string;
	browser_version?: string;
	device_manufacturer?: string;
	device_model?: string;
	device_os?: string;
	device_os_version?: string;
	cohort_id?: string;
	app_session?: string;
	utm_source?: string;
	utm_campaign?: string;
	utm_medium?: string;
	utm_content?: string;
	utm_term?: string;
	logged_in_flag?: boolean;
	cc_category?: string;
}

class TrackEvents {
	static baseProperties: BaseProperties = {};
	static dueEvents: Array<any> = [];

	/**
	 * profile array
	 * @param {string} name - The name of the site
	 * @param {string} email - The email address of the user.
	 */
	static setupProfile(name: string, email: string) {
		// If the clevertap object is defined in the window object, then push a profile object to the clevertap
		if ('clevertap' in window) {
			window.clevertap.profile.push({
				Site: {
					Name: name,
					Email: email,
				},
			});
		}
		// If the insp object is defined in the window object, then push a profile object to the inspectlet
		if ('__insp' in window) {
			window?.__insp.push(['identify', email]);
			window?.__insp.push(['tagSession', { email: email, name: name }]);
		}
	}

	static setupBaseProperties() {
		const params = new URLSearchParams(window.location.search);
		this.baseProperties = Object.assign(this.baseProperties, {
			devicetype: window.innerWidth >= 768 ? 'dWeb' : 'mWeb', // TODO: breakpoint constant
			width: `${window.innerWidth}px`,
			height: `${window.innerHeight}px`,
			browser: platform.name, // 'Safari'
			browser_version: platform.version, // '5.1'
			device_manufacturer: platform.manufacturer, // 'Apple'
			device_model: platform.product, // 'iPad'
			device_os: platform?.os?.family,
			device_os_version: platform?.os?.version,
			app_session: getCookie(cookies.SESSION_ID_COOKIE),
			utm_source: params.get('utm_source') || params.get('ps') || '',
			utm_campaign: params.get('utm_campaign') || '',
			utm_medium: params.get('utm_medium') || '',
			utm_content: params.get('utm_content') || '',
			utm_term: params.get('utm_term') || '',
			logged_in_flag: !!getCookie(cookies.AUTH_TOKEN_COOKIE),
			cc_category: 'job_board',
		});
	}

	static updateBaseProperties(user: UserLeadInfo) {
		if (!this.baseProperties) {
			TrackEvents.setupBaseProperties();
		}
		this.baseProperties.user_id = user.user.id;
		this.baseProperties.email = user.user.email;
		this.baseProperties.logged_in_flag = !!getCookie(cookies.AUTH_TOKEN_COOKIE);
	}

	static executeDueEvents() {
		TrackEvents.dueEvents.forEach((item) => {
			this.log(item.type, item.data);
		});
		TrackEvents.dueEvents = [];
	}

	static triggerIntialEvents() {
		TrackEvents.setupBaseProperties();
		TrackEvents.executeDueEvents();
	}

	static log(type: any, data: any = {}) {
		const { pageCategory, pageTitle } = getCurrentPage() || {};
		if (this.baseProperties) {
			const properties = {
				...data,
				...this.baseProperties,
				page_title: pageTitle,
				page_category: pageCategory,
				page_url: window.location.href,
				logged_in_flag: !!getCookie(cookies.AUTH_TOKEN_COOKIE),
			};
			logger.log(type, { ...properties });
			window.clevertap.event.push(type, { ...properties });
		} else {
			this.dueEvents.push({ type, data });
			TrackEvents.triggerIntialEvents();
		}
	}
}

function getCurrentPage() {
	const pathname = window.location.pathname;
	return routes.find((route: any) => pathname.startsWith(route.matchPath));
}

export default TrackEvents;
