import {
	Action,
	action,
	actionOn,
	ActionOn,
	Computed,
	computed,
	Thunk,
	thunk,
} from 'easy-peasy';

import api from './service';
import { setCookie, deleteAllCookies } from 'utils/cookies';
import { AuthRedirectionFrom, cookies } from '../../constants';
import {
	ForgotPasswordAPIRequestPayload,
	ChangePasswordAPIRequestPayload,
	LoginAPIRequestPayload,
	UserLeadInfo,
} from 'types/auth-services';
import { StoreModel } from 'store';
import { convertUserToCustomUser } from 'utils/user';
import { User } from 'types/career-services';
import { AxiosError } from 'axios';
import { getRandomString } from 'utils/helpers';
import { DropLeadPayload } from 'types/auth-services/models/User';

const { AUTH_TOKEN_COOKIE, SESSION_ID_COOKIE } = cookies;

export interface UserStore {
	data: User | null;
	isLoggedIn: Computed<UserStore, boolean>;
	isLoggedInByFlow: boolean;
	isLoginModalOpen: boolean;
	isLoggedInFrom: AuthRedirectionFrom | null;
	loading: boolean;
	userLeadInfo: UserLeadInfo | null;
	// ********** Actions ***************
	setIsLoggedInByFlow: Action<UserStore>;
	resetLoginFlow: Action<UserStore>;
	setIsLoginModalOpen: Action<UserStore, AuthRedirectionFrom>;
	resetIsLoginModalOpen: Action<UserStore>;
	setIsLoggedInFrom: Action<UserStore, AuthRedirectionFrom>;
	setData: Action<UserStore, User>;
	resetData: Action<UserStore>;
	setLoading: Action<UserStore, boolean>;
	resetLoading: Action<UserStore>;
	reset: Action<UserStore>;
	loginByFlow: Action<UserStore, string>;
	setUserLeadInfo: Action<UserStore, UserLeadInfo>;
	// ********** Thunks ***************
	login: Thunk<UserStore, LoginAPIRequestPayload>;
	logout: Thunk<UserStore, undefined>;
	logoutByFlow: Thunk<UserStore, undefined>;
	forgotPassword: Thunk<UserStore, ForgotPasswordAPIRequestPayload>;
	changePassword: Thunk<UserStore, ChangePasswordAPIRequestPayload>;
	fetchUserLeadInfo: Thunk<UserStore>;
	dropLead: Thunk<
		UserStore,
		DropLeadPayload
	>;

	// ********** Listeners ***************
	onSetCCLaunchData: ActionOn<UserStore, StoreModel>;
}

const user: UserStore = {
	// ********** Data ***************
	data: null,
	isLoggedIn: computed((state) => state.data != null),
	isLoggedInByFlow: false, // login from @upgrad-accounts/flow
	isLoginModalOpen: false,
	isLoggedInFrom: null,
	loading: false,
	userLeadInfo: null,
	// ********** Actions ***************
	setIsLoggedInByFlow: action((state) => {
		state.isLoggedInByFlow = true;
	}),
	resetLoginFlow: action((state) => {
		state.isLoggedInByFlow = false;
		state.isLoginModalOpen = false;
		state.isLoggedInFrom = null;
	}),
	setIsLoginModalOpen: action((state, payload) => {
		state.isLoginModalOpen = true;
		state.isLoggedInFrom = payload;
	}),
	resetIsLoginModalOpen: action((state) => {
		state.isLoginModalOpen = false;
	}),
	setIsLoggedInFrom: action((state, payload) => {
		state.isLoggedInFrom = payload;
	}),
	setData: action((state, payload) => {
		const customUser = convertUserToCustomUser(payload);
		state.data = customUser;
	}),
	resetData: action((state, payload) => {
		state.data = null;
	}),
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	resetLoading: action((state, payload) => {
		state.loading = false;
	}),
	reset: action((state, payload) => {
		console.log('%c UserStore::', 'color:gold', 'inside reset==>');
		state.data = null;
		state.loading = false;
	}),
	loginByFlow: action((state, payload) => {
		setCookie({
			[AUTH_TOKEN_COOKIE]: payload,
		});
		// set sessionId cookie needed by LEARN
		setCookie({
			[SESSION_ID_COOKIE]: getRandomString(32),
		});
		state.isLoginModalOpen = false;
		state.isLoggedInByFlow = true;
	}),
	setUserLeadInfo: action((state, payload) => {
		state.userLeadInfo = payload;
	}),
	// ********** Thunks ***************
	login: thunk(async (actions, payload) => {
		actions.setLoading(true);
		const { username, password } = payload;
		try {
			const response = await api.login({ username, password });
			const authToken = response.headers['auth-token'];
			// setting auth token in cookie
			setCookie({
				[AUTH_TOKEN_COOKIE]: authToken,
			});
			// set sessionId cookie needed by LEARN
			setCookie({
				[SESSION_ID_COOKIE]: getRandomString(32),
			});
			actions.setLoading(false);
		} catch (err: any) {
			const error = (err as AxiosError).response?.data;
			console.log('%c UserStore::', 'color:gold', 'caught error', error);
			actions.setLoading(false);
			throw error;
		}
	}),
	logout: thunk(async (actions, payload) => {
		deleteAllCookies();
		console.log('%c UserStore::', 'color:gold', 'calling reset==>');
		actions.reset();
		await api.logout();
	}),
	logoutByFlow: thunk(async (actions, payload) => {
		deleteAllCookies();
		actions.resetLoginFlow();
		await api.logout();
	}),
	forgotPassword: thunk(async (actions, payload) => {
		return api.forgotPassword(payload);
	}),
	changePassword: thunk(async (actions, payload) => {
		return api.changePassword(payload);
	}),
	fetchUserLeadInfo: thunk(async (actions) => {
		const response = await api.fetchUserLeadInfo();
		actions.setUserLeadInfo(response);
		return response;
	}),
	dropLead: thunk(async (actions, payload) => {
		return api.dropLead(payload);
	}),
	// ********** Listeners ***************
	onSetCCLaunchData: actionOn(
		(actions, storeActions) => storeActions.learnerController.setLaunchData,
		(state, target) => {
			const { payload } = target;
			const { data } = payload;

			if (!state.data && data && data.user) {
				// const customUser = convertUserToCustomUser(data.user);
				state.data = data.user;
			}
		},
	),
};

export default user;
