interface RouteMetaData {
	key: string;
	path: string;
	matchPath: string;
	pageCategory: string;
	pageTitle: string;
}

enum RouteKeys {
	ALLJOBS = 'all-jobs',
	RECOMMENDEDJOBS = 'recommended-jobs',
	SAVEDJOBS = 'saved-jobs',
	APPLIEDJOBS = 'applied-jobs',
	JOBDETAILS = 'job-details',
	JOBBOARDDETAILS = 'job-board-details',
	JOBBOARDCOURSES = 'job-board-courses',
	JOBBOARD = 'job-board',
}

export const routes: Array<RouteMetaData> = [
	{
		key: RouteKeys.ALLJOBS,
		path: '/jobs/all',
		matchPath: '/jobs/all',
		pageCategory: 'Jobs Revamp',
		pageTitle: 'All Jobs',
	},
	{
		key: RouteKeys.RECOMMENDEDJOBS,
		path: '/jobs/recommended',
		matchPath: '/jobs/recommended',
		pageCategory: 'Jobs Revamp',
		pageTitle: 'Recommended Jobs',
	},
	{
		key: RouteKeys.SAVEDJOBS,
		path: '/jobs/saved',
		pageCategory: 'Jobs Revamp',
		pageTitle: 'Saved Jobs',
		matchPath: '/jobs/saved',
	},
	{
		key: RouteKeys.APPLIEDJOBS,
		path: '/jobs/applied',
		matchPath: '/jobs/applied',
		pageCategory: 'Jobs Revamp',
		pageTitle: 'Applied Jobs',
	},
	{
		key: RouteKeys.JOBDETAILS,
		path: '/jobs/details/:jobId',
		matchPath: '/jobs/details',
		pageCategory: 'Jobs Revamp',
		pageTitle: 'Job Details',
	},
	{
		key: RouteKeys.JOBBOARDDETAILS,
		path: '/details/:jobId',
		matchPath: '/details',
		pageCategory: 'Job Board',
		pageTitle: 'Job Board Details',
	},
	{
		key: RouteKeys.JOBBOARDCOURSES,
		path: '/courses',
		matchPath: '/courses',
		pageCategory: 'Job Board',
		pageTitle: 'courses',
	},
	{
		key: RouteKeys.JOBBOARD,
		path: '/',
		matchPath: '/',
		pageCategory: 'Job Board',
		pageTitle: 'Job Board',
	},
];
