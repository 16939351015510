const ERROR_MSG_GENERIC =
	'Unable to fetch the Jobs, Please refresh the page or try again later';

export default Object.freeze({
	ACTION: {
		SUCCESS_MSG: '',
		ERROR_MSG: '',
	},
	APPLICATION_STATUS: {
		SUCCESS_MSG: '',
		ERROR_MSG: 'Unable to Fetch the latest Job application status',
	},
	APPLIED_JOBS: {
		SUCCESS_MSG: '',
		ERROR_MSG: ERROR_MSG_GENERIC,
	},
	// APPLIED_OUTSIDE: `${JOB_PREFIX_URL}/user/actions/has_applied/:jobID`,
	APPLY_JOB: {
		SUCCESS_MSG: 'You have successfully Applied!',
		ERROR_MSG: 'Unable to Apply the job',
	},
	BOOKMARK: {
		SUCCESS_MSG: '',
		ERROR_MSG_BOOKMARK: 'Unable to Bookmark the job',
		ERROR_MSG_NOT_BOOKMARK: 'Unable to delete Bookmark for the job',
	},
	// CLICKED_OUTSIDE: `${JOB_PREFIX_URL}/user/actions/clicked_outside/:jobID`,
	// FILTER_OPTION_VALUES: `${JOB_PREFIX_URL}/filter/`,
	JOBS: {
		SUCCESS_MSG: '',
		ERROR_MSG: ERROR_MSG_GENERIC,
	},
	// JOB_METADATA: `${JOB_PREFIX_URL}/metadata/`,
	JOB_DETAILS: {
		SUCCESS_MSG: '',
		ERROR_MSG: 'Unable to fetch the Job details',
	},
	// JOB_DESCRIPTION: 'user/job/:jobID',
	// RECENT_SEARCHES: `${JOB_PREFIX_URL}/recent/searches/`,
	RECOMMENDED_JOBS: {
		SUCCESS_MSG: '',
		ERROR_MSG: ERROR_MSG_GENERIC,
	},
	RELEVANCE: {
		SUCCESS_MSG: 'We have received your rating',
		ERROR_MSG: 'An error occured when trying to register your feedback',
	},
	RESUME_LIST: {
		SUCCESS_MSG: '',
		ERROR_MSG: 'Unable to fetch the Resumes',
	},
	SAVED_JOBS: {
		SUCCESS_MSG: '',
		ERROR_MSG: ERROR_MSG_GENERIC,
	},
	// USER_APPLICATIONS: '/user/application/:applicationID',
});
