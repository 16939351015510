export enum Color {
	PRIMARY = '#4d8af0',
	SUCCESS = '#22af73',
	ERROR = '#eb5760',
	GREY1 = '#7C8698',
	WHITE = '#FFF',
	GREY2 = '#45526C',
	GREEN = '#02A971',
	GREY3 = '#B4BAC4',
	RED = '#EF403D',
}

export const cohortColors = [
	'#F66B68',
	'#5097E2',
	'#1CB9C7',
	'#F7915E',
	'#34ADA3',
	'#3F6799',
	'#6458A6',
	'#2D92BA',
	'#C24C65',
	'#F7AF43',
];
