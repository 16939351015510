export enum LeadTouchPoints {
	JobTopSectionSignup = 'Job_TopSection_Signup',
	JobTopSectionSignin = 'Job_TopSection_Signin',
	JobApplySignup = 'Job_Apply_Signup',
	JobApplySignin = 'Job_Apply_Signin',
	JobboardEndSignup = 'Jobboard_End_Signup',
	JobboardEndSignin = 'Jobboard_End_Signin',
}

enum extraFields {
	JOBDOMAIN = 'jobDomain',
	JOBTOTALEXPERIENCE = 'jobTotalExperience',
	JOBRELEVANTEXPERIENCE = 'jobRelevantExperience',
	JOBSALARY = 'jobSalary',
	JOBLOCATION = 'jobLocation',
}

/* Contains all the individual constants related to feature `leadDrop`. */
export const leadDropConstants = {
	course: 'CC_Jobboard',
	extraFields: extraFields
}
