// import { createAxios } from '../../utils/custom-axios';
import axios from 'axios';

import { getEnv } from 'utils/env';
import { SelfEnrollPayload } from 'types/custom';

const BASE_URL = getEnv('courseServiceBaseUrl');
const URL = {
	SELF_ENROLL: `${BASE_URL}/v3/enrollments/selfEnroll`,
};

export default (() => {
	return {
		selfEnroll: (payload: SelfEnrollPayload) =>
			axios.post<any>(URL.SELF_ENROLL, payload),
	};
})();
