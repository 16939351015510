import {
	Action,
	action,
	computed,
	Computed,
	Thunk,
	thunk,
	thunkOn,
	ThunkOn,
} from 'easy-peasy';
import { StoreModel } from 'store';

import {
	StandardResponseCCLaunchResponseModel,
	StandardResponseShowTabFirst,
	Cohort,
} from 'types/career-services';
import api from './service';
import { LongToast } from 'services';
import { getCookie, setCookie } from 'utils/cookies';
import { cookies } from '../../constants';
import { ProfileData } from 'types/custom';

export interface LearnerControllerStore {
	// ********** Data ***************
	launchData: StandardResponseCCLaunchResponseModel | null;
	firstTab: StandardResponseShowTabFirst.data | null;
	profileData: ProfileData | null;
	isProfileComplete: Computed<LearnerControllerStore, boolean | 'unknown'>;
	// ********** Actions ***************
	setLaunchData: Action<
		LearnerControllerStore,
		StandardResponseCCLaunchResponseModel
	>;
	resetLaunchData: Action<LearnerControllerStore>;
	selectedCohort: Cohort | null;
	setFirstTab: Action<
		LearnerControllerStore,
		StandardResponseShowTabFirst.data
	>;
	resetFirstTab: Action<LearnerControllerStore>;
	reset: Action<LearnerControllerStore>;
	setSelectedCohort: Action<LearnerControllerStore, Cohort>;
	resetSelectedCohort: Action<LearnerControllerStore>;
	setProfileData: Action<LearnerControllerStore, ProfileData>;
	resetProfileData: Action<LearnerControllerStore>;
	// ********** Thunks ***************
	getLaunchData: Thunk<LearnerControllerStore, undefined, StoreModel>;
	getFirstTab: Thunk<LearnerControllerStore, undefined>;
	getProfileData: Thunk<LearnerControllerStore, undefined>;
	getHirationToken: Thunk<LearnerControllerStore, undefined>;
	// ********** Listeners ***************
	onSetCCLaunchData: ThunkOn<LearnerControllerStore, any, StoreModel>;
	onUserReset: ThunkOn<LearnerControllerStore, any, StoreModel>;
}

const learnerController: LearnerControllerStore = {
	// ********** Data ***************
	launchData: null,
	firstTab: null,
	selectedCohort: null,
	profileData: null,
	isProfileComplete: computed((state) => {
		if (state.profileData === null) return 'unknown';
		return (
			!!state.profileData?.currentResidence &&
			((!!state.profileData?.preferedLocation &&
				Object.keys(state.profileData?.preferedLocation).length > 0) ||
				!!state.profileData?.otherPreferedCity) &&
			!!state.profileData?.education?.length
		);
	}),
	// ********** Actions ***************
	reset: action((state, payload) => {
		state.launchData = null;
		state.firstTab = null;
		state.selectedCohort = null;
		state.profileData = null;
	}),
	setLaunchData: action((state, payload) => {
		state.launchData = payload;
	}),
	resetLaunchData: action((state, payload) => {
		state.launchData = null;
	}),
	setFirstTab: action((state, payload) => {
		state.firstTab = payload;
	}),
	resetFirstTab: action((state, payload) => {
		state.firstTab = null;
	}),
	setSelectedCohort: action((state, payload) => {
		state.selectedCohort = payload;
	}),
	resetSelectedCohort: action((state, payload) => {
		state.selectedCohort = null;
	}),
	setProfileData: action((state, payload) => {
		state.profileData = payload;
	}),
	resetProfileData: action((state, payload) => {
		state.profileData = null;
	}),
	// ********** Thunks ***************
	getLaunchData: thunk(async (actions, payload, { getStoreActions }) => {
		// const { data: launchData } = await api.getCCLaunchData();
		try {
			const launchData = await api.getCCLaunchData();
			if (!launchData.errorCode) {
				actions.setLaunchData(launchData);
			} else if (launchData.message === 'User details missing') {
				throw Error('User details are not found');
			} else {
				throw Error('User details are corrupted');
			}
		} catch (err: any) {
			if (err?.error === 'Unauthorized' && err.status === 401) {
				LongToast.error({
					title: 'Error! Bad Credentials',
					description: 'User is not Authorized',
				});
			} else {
				LongToast.error({
					title: 'Error! Bad Credentials',
					description: err.message || 'Unable to Fetch user details',
				});
			}
			throw err;
		}
	}),
	getFirstTab: thunk(async (actions, payload) => {
		const response = await api.getFirstTab();
		const firstTab = response.data;
		firstTab && actions.setFirstTab(firstTab);
	}),
	getProfileData: thunk(async (actions) => {
		const courseId = getCookie(cookies.COURSE_ID_COOKIE) || '';
		const profileData = await api.getProfileData(courseId);
		actions.setProfileData(profileData);
	}),
	getHirationToken: thunk(async (actions) => {
		try {
			const hirationToken = await api.getHirationToken();
			setCookie({ [cookies.HIRATION_AUTH]: hirationToken });
		} catch (error) {
			console.error('Unable to fetch Hiration Token', error);
		}
	}),
	// ********** Listeners ***************
	onSetCCLaunchData: thunkOn(
		(actions) => actions.setLaunchData,
		async (actions, target) => {
			await actions.getFirstTab();
			// getCookie(cookies.COURSE_ID_COOKIE) && TrackEvents.triggerIntialEvents();
			// call hiration API if hiration cookie is not present
			!getCookie(cookies.HIRATION_AUTH) && (await actions.getHirationToken());
		},
	),
	onUserReset: thunkOn(
		(actions, storeActions) => storeActions.user.reset,
		async (actions, target) => {
			await actions.reset();
		},
	),
};

export default learnerController;
