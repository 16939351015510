import { getEnv } from './env';
import { cookies } from 'constants/index';

const domainString =
	process.env.NODE_ENV === 'development'
		? ''
		: `domain=${getEnv('cookieDomain')}`;

const setCookie = (obj: any, expiry = null) => {
	Object.keys(obj).forEach((key) => {
		const expirySetting = expiry ? `; cookie=ok;expires=${expiry}` : '';
		document.cookie = `${key}=${obj[key]}; path=/; ${domainString}${expirySetting}`;
	});
};

const setCookieWithExpiry = (key: string, value: string, days: number) => {
	const date = new Date();
	const daysBeforeExpiry = days || 365;
	date.setTime(date.getTime() + daysBeforeExpiry * 86400000);
	document.cookie = `${key}=${value}; path=/;expires=${date.toUTCString()}; ${domainString}`;
};

const getCookie = (name: string) => {
	const value = `; ${document.cookie}`;
	const parts = value.split(`; ${name}=`);
	if (parts.length === 2) {
		return parts.pop()?.split(';').shift();
	}
	return '';
};

const deleteCookie = (keys: string[]) => {
	keys.forEach((k) => {
		document.cookie = `${k}=; Path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT; ${domainString}`;
	});
};

function deleteAllCookies() {
	// delete only main cookies
	const {
		AUTH_TOKEN_COOKIE,
		COURSE_ID_COOKIE,
		SESSION_ID_COOKIE,
		HIRATION_AUTH,
	} = cookies;
	deleteCookie([
		AUTH_TOKEN_COOKIE,
		COURSE_ID_COOKIE,
		SESSION_ID_COOKIE,
		HIRATION_AUTH,
	]);
}

export {
	setCookie,
	getCookie,
	deleteCookie,
	setCookieWithExpiry,
	deleteAllCookies,
};
