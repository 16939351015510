import { TypedObject } from 'types/custom';

export const EncodedURLParamKeys: TypedObject<string> = {
	sortBy: 'sort',
	searchKeywords: 'search',
	ps: 'ps',
	'pagination.pageNumber': 'page',
	'filters.easyApply': 'easy',
	'filters.fields': 'domains',
	'filters.experience.total.min': 'totalMin',
	'filters.experience.total.max': 'totalMax',
	'filters.experience.relevant.min': 'relevantMin',
	'filters.experience.relevant.max': 'relevantMax',
	'filters.salary.range.min': 'salaryMin',
	'filters.salary.range.max': 'salaryMax',
	'filters.locations': 'locations',
	'filters.companies': 'companies',
	'filters.appliedJobsSource': 'source',
};
export const DecodedURLParamKeys: TypedObject<string> = {
	sort: 'sortBy',
	search: 'searchKeywords',
	ps: 'ps',
	page: 'pagination.pageNumber',
	easy: 'filters.easyApply',
	domains: 'filters.fields',
	totalMin: 'filters.experience.total.min',
	totalMax: 'filters.experience.total.max',
	relevantMin: 'filters.experience.relevant.min',
	relevantMax: 'filters.experience.relevant.max',
	salaryMin: 'filters.salary.range.min',
	salaryMax: 'filters.salary.range.max',
	locations: 'filters.locations',
	companies: 'filters.companies',
	source: 'filters.appliedJobsSource',
};

export const ParamDataType: TypedObject<
	'string' | 'number' | 'array' | 'boolean'
> = {
	sort: 'string',
	search: 'array',
	ps: 'string',
	page: 'number',
	easy: 'boolean',
	domains: 'array',
	totalMin: 'number',
	totalMax: 'number',
	relevantMin: 'number',
	relevantMax: 'number',
	salaryMin: 'number',
	salaryMax: 'number',
	locations: 'array',
	companies: 'array',
	source: 'array',
};

export const RedirectConstants: TypedObject<string> = {
	pageSource: 'ps',
	freeUserExperiencePageKey: 'fue',
};
