import { message } from 'antd';

export function success(content: string) {
	message.success(content);
}

export function error(content: string) {
	message.error(content);
}

export function warning(content: string) {
	message.warning(content);
}

export function info(content: string) {
	message.info(content);
}

const obj = { success, error, warning, info };
export default obj;
