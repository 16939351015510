import Rollbar from 'rollbar';
import { getEnv } from '../utils/env';

const rollbarConfig = {
	enabled: process.env.NODE_ENV === 'production',
	accessToken: getEnv('rollbarAppKey'),
	captureUncaught: true,
	captureUnhandledRejections: true,
	autoInstrument: true,
	payload: {
		environment: getEnv('env'),
	},
};

const rollbar = new Rollbar(rollbarConfig);
export default rollbar;
