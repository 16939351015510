import { Filters } from 'types/custom';
import { FilerTypeEnum, JobFiltersKeysEnum } from 'constants/filters';
import { JobsRequestFilterSortModel } from 'types/career-services';
import { DeviceType } from '../../../constants';

export const DEFAULT_QUERY_PAYLOAD: JobsRequestFilterSortModel = {
	sortBy: 'recent',
	searchKeywords: [],
	pagination: { pageNumber: 0, pageSize: 10 },
	filters: {
		easyApply: true,
		fields: [],
		experience: {
			total: { min: undefined, max: undefined },
			relevant: { min: undefined, max: undefined },
		},
		salary: {
			range: { min: undefined, max: undefined },
		},
	},
};

export const FILTERS_DATA: Filters = [
	{
		key: JobFiltersKeysEnum.Field,
		label: 'Domain',
		isExpanded: false,
		type: FilerTypeEnum.CHECKBOX,
		options: [],
		defaultValue: [],
		headerClassName: 'collapseHeader',
		devices: [DeviceType.DESKTOP, DeviceType.MOBILE, DeviceType.TABLET],
	},
	{
		key: JobFiltersKeysEnum.Experience,
		label: 'Experience',
		isExpanded: false,
		type: null,
		headerClassName: 'collapseHeader',
		devices: [DeviceType.DESKTOP, DeviceType.MOBILE, DeviceType.TABLET],
		children: [
			{
				key: JobFiltersKeysEnum.TotalExperience,
				label: 'Total experience (in years)',
				isExpanded: true,
				type: FilerTypeEnum.RANGE,
				min: { label: '0 yrs', value: 0 },
				max: { label: '30+ yrs', value: 31 },
				step: 1,
				defaultValue: { min: undefined, max: undefined },
				tooltipSuffix: 'yrs',
				headerClassName: 'collapseChildHeader',
				devices: [DeviceType.DESKTOP, DeviceType.MOBILE, DeviceType.TABLET],
			},
			{
				key: JobFiltersKeysEnum.RelevantExperience,
				label: 'Relevant experience (in years)',
				isExpanded: true,
				type: FilerTypeEnum.RANGE,
				min: { label: '0 yrs', value: 0 },
				max: { label: '30+ yrs', value: 31 },
				step: 1,
				defaultValue: { min: undefined, max: undefined },
				tooltipSuffix: 'yrs',
				headerClassName: 'collapseChildHeader',
				devices: [DeviceType.DESKTOP, DeviceType.MOBILE, DeviceType.TABLET],
			},
		],
	},
	{
		key: JobFiltersKeysEnum.Location,
		label: 'Location',
		isExpanded: false,
		type: FilerTypeEnum.CHECKBOX,
		options: [],
		defaultValue: [],
		headerClassName: 'collapseHeader',
		devices: [DeviceType.DESKTOP, DeviceType.MOBILE, DeviceType.TABLET],
	},
	{
		key: JobFiltersKeysEnum.Salary,
		label: 'Salary',
		isExpanded: false,
		type: null,
		headerClassName: 'collapseHeader',
		devices: [DeviceType.DESKTOP, DeviceType.MOBILE, DeviceType.TABLET],
		children: [
			{
				key: JobFiltersKeysEnum.SalaryRange,
				label: 'Salary range(Per annum)',
				isExpanded: true,
				type: FilerTypeEnum.RANGE,
				min: { label: '0 LPA', value: 0 },
				max: { label: '30+ LPA', value: 31 },
				step: 1,
				defaultValue: { min: undefined, max: undefined },
				tooltipSuffix: 'LPA',
				headerClassName: 'collapseChildHeader',
				devices: [DeviceType.DESKTOP, DeviceType.MOBILE, DeviceType.TABLET],
			},
		],
	},
];
