import { ProfileMissingData } from '../types/custom/models/ProfileMissingData';
import { getEnv } from '../utils/env';

export const msgParts: { [part in ProfileMissingData]: string } = Object.freeze(
	{
		all: 'Profile and Resume',
		profile: 'Profile',
		resume: 'Resume',
	},
);
export const profileLinks: { [part in ProfileMissingData]: string } =
	Object.freeze({
		all: `${getEnv('OLD_CAREER_URL')}/account/education-and-work`,
		profile: `${getEnv('OLD_CAREER_URL')}/account/education-and-work`,
		resume: `${getEnv('OLD_CAREER_URL')}/account/resume`,
	});
