import { Grid } from 'antd';
import { DeviceType } from '../constants';

const { useBreakpoint } = Grid;

const useResponsive = () => {
	const screens = useBreakpoint();
	const deviceType = getDeviceType();

	function getDeviceType() {
		if (screens.lg) return DeviceType.DESKTOP;
		if (screens.md) return DeviceType.TABLET;
		return DeviceType.MOBILE;
	}

	return {
		deviceType,
		isDesktop: deviceType === DeviceType.DESKTOP,
		isTablet: deviceType === DeviceType.TABLET,
		isMobile: deviceType === DeviceType.MOBILE,
		screens,
	};
};
export default useResponsive;
