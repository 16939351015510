import {
	action,
	Action,
	Thunk,
	thunk,
	ThunkOn,
	thunkOn,
	ActionOn,
	actionOn,
} from 'easy-peasy';
import { set as lodashSet } from 'lodash-es';

// types
import {
	JobMetadata,
	JobsRequestFilterSortModel,
	JobsRequestPaginationModel,
	PageElasticJobs,
} from 'types/career-services';
import {
	Filters,
	ElasticMappedJobs as MappedJobs,
	LabelValuePair,
} from 'types/custom';

import api from './../service';
import { StoreModel } from '../../index';
import { DEFAULT_QUERY_PAYLOAD, FILTERS_DATA } from './data';
import { JobFiltersKeysEnum } from 'constants/index';
import { ArrayToObj } from 'utils/mapper';
export interface JobBoardStore {
	// ********** Data ***************
	data: PageElasticJobs | null;
	filterSchemas: Filters;
	queryPayload: JobsRequestFilterSortModel;
	mappedJobs: MappedJobs | null;
	loading: boolean;

	// ********** Actions ***************
	reset: Action<JobBoardStore>;
	setData: Action<JobBoardStore, PageElasticJobs>;
	resetData: Action<JobBoardStore>;
	setQueryPayload: Action<JobBoardStore, JobsRequestFilterSortModel>;
	resetQueryPayload: Action<JobBoardStore>;
	updateFilters: Action<JobBoardStore, { key: string; value: any }>;
	setSortBy: Action<JobBoardStore, string>;
	resetSortBy: Action<JobBoardStore>;
	setSearchKeywords: Action<JobBoardStore, string[]>;
	resetSearchKeywords: Action<JobBoardStore>;
	setPagination: Action<JobBoardStore, JobsRequestPaginationModel>;
	resetPagination: Action<JobBoardStore>;
	toggleLoading: Action<JobBoardStore, boolean>;
	setMappedJobs: Action<JobBoardStore, MappedJobs>;
	resetMappedJobs: Action<JobBoardStore>;
	// ********** Thunks ***************
	fetchAllJobs: Thunk<JobBoardStore, undefined>;
	fetchJobsMeta: Thunk<JobBoardStore, { jobIds: number[] }>;

	// ********** Listeners ***************
	onUserReset: ThunkOn<JobBoardStore, any, StoreModel>;
	onSetData: ThunkOn<JobBoardStore, any, StoreModel>;
	onSetFilterOptions: ActionOn<JobBoardStore, StoreModel>;
}

const jobBoard: JobBoardStore = {
	// ********** Data ***************
	data: null,
	filterSchemas: FILTERS_DATA,
	queryPayload: DEFAULT_QUERY_PAYLOAD,
	mappedJobs: null,
	loading: false,
	// ********** Actions ***************
	reset: action((state, payload) => {
		state.data = null;
		state.queryPayload = DEFAULT_QUERY_PAYLOAD;
		state.mappedJobs = null;
		state.loading = false;
	}),
	setData: action((state, payload) => {
		state.mappedJobs = null; // to reset mappedJobs to show latest request jobs and not previous requested jobs
		state.data = payload;
	}),
	resetData: action((state, payload) => {
		state.data = null;
	}),
	setQueryPayload: action((state, payload) => {
		state.queryPayload = payload;
	}),
	resetQueryPayload: action((state, payload) => {
		// const queryPayload = state.queryPayload;
		// queryPayload.filters = DEFAULT_QUERY_PAYLOAD.filters;
		// queryPayload.pagination = DEFAULT_QUERY_PAYLOAD.pagination;
		state.queryPayload = DEFAULT_QUERY_PAYLOAD;
	}),
	updateFilters: action((state, payload) => {
		const { key, value } = payload;
		let filters: any = state.queryPayload.filters;
		lodashSet(filters, key, value);
		const pagination = DEFAULT_QUERY_PAYLOAD.pagination;
		state.queryPayload = { ...state.queryPayload, pagination, filters };
	}),
	setSortBy: action((state, payload) => {
		state.queryPayload.pagination = DEFAULT_QUERY_PAYLOAD.pagination;
		state.queryPayload.sortBy = payload;
	}),
	resetSortBy: action((state, payload) => {
		state.queryPayload.pagination = DEFAULT_QUERY_PAYLOAD.pagination;
		state.queryPayload.sortBy = DEFAULT_QUERY_PAYLOAD.sortBy;
	}),
	setSearchKeywords: action((state, payload) => {
		state.queryPayload.searchKeywords = payload;
	}),
	resetSearchKeywords: action((state, payload) => {
		state.queryPayload.searchKeywords = DEFAULT_QUERY_PAYLOAD.searchKeywords;
	}),
	setPagination: action((state, payload) => {
		state.queryPayload.pagination = payload;
	}),
	resetPagination: action((state, payload) => {
		state.queryPayload.pagination = DEFAULT_QUERY_PAYLOAD.pagination;
	}),
	setMappedJobs: action((state, payload) => {
		state.mappedJobs = payload;
	}),
	resetMappedJobs: action((state, payload) => {
		state.mappedJobs = null;
	}),
	toggleLoading: action((state, payload) => {
		state.loading = payload;
	}),
	// ********** Thunks ***************
	fetchAllJobs: thunk(async (actions, payload, { getState }) => {
		// Loading Intializing
		actions.toggleLoading(true);

		const allJobsPayload = getState().queryPayload;
		const response = await api.fetchAllJobs(allJobsPayload);
		actions.setData(response);
		actions.setMappedJobs(response.content || []);
		actions.toggleLoading(false);
	}),
	fetchJobsMeta: thunk(async (actions, payload, { getState }) => {
		const response = await api.fetchJobsMetaData({
			ids: payload.jobIds,
		});

		// check if there are any metadata to be mapped
		if (!response.length) {
			return;
		}

		const metaMap = ArrayToObj(response, 'jobId') as {
			[key: number]: JobMetadata;
		};

		const jobs = getState().data?.content || [];
		const mappedJobs = jobs.map((job) => {
			const jobId = job.id as number;
			// return { ...job, meta:metaMap[jobId], userAction:actionsMap[jobId] };
			return { ...job, ...metaMap[jobId] };
		});
		actions.setMappedJobs(mappedJobs);
	}),
	// ********** Listeners ***************
	onUserReset: thunkOn(
		(actions, storeActions) => storeActions.user.reset,
		async (actions, target) => {
			await actions.reset();
		},
	),
	onSetData: thunkOn(
		(actions) => actions.setData,
		async (actions, target) => {
			const jobs = target?.payload?.content || [];
			// using as keyword because we know job.id can never be undefined
			const jobIds = jobs.map((job) => job.id) as number[];
			jobIds.length && actions.fetchJobsMeta({ jobIds });
		},
	),
	onSetFilterOptions: actionOn(
		(actions, storeActions) => storeActions.jobs.setFilterOptions,
		(state, target) => {
			const { payload: filterOptions } = target;
			let updatedFilterSchema = state.filterSchemas.map((filterObj) => {
				if (filterObj.key === JobFiltersKeysEnum.Field) {
					if ('options' in filterObj) {
						let options = filterOptions?.fields || [];
						const mappedOptions: LabelValuePair<string>[] = [];
						options.forEach((option) => {
							if (option['filterKey'] && option['filterText']) {
								mappedOptions.push({
									value: option['filterKey'],
									label: option['filterText'],
								});
							}
						});
						filterObj.options = mappedOptions;
					}
				} 
				else if (filterObj.key === JobFiltersKeysEnum.Location) {
					if ('options' in filterObj) {
						const mappedOptions: LabelValuePair<string>[] = [];
						filterOptions?.locations?.forEach((option: any) => {
							mappedOptions.push({
								label: option.baseLocation,
								value: option.baseLocation,
							});
						});
						filterObj.options = mappedOptions;
					}
				}
				return filterObj;
			});
			state.filterSchemas = updatedFilterSchema;
		},
	),
};

export default jobBoard;
