import { notification } from 'antd';

export interface NotificationConfig {
	title?: string;
	description: string;
}

export function success(config: NotificationConfig) {
	const { title: message = 'Success', description } = config;
	notification.success({ message, description });
}
export function error(config: NotificationConfig) {
	const { title: message = 'Something went wrong', description } = config;
	notification.error({ message, description });
}
export function warning(config: NotificationConfig) {
	const { title: message = 'Warning', description } = config;
	notification.warning({ message, description });
}
export function info(config: NotificationConfig) {
	const { title: message = 'Info', description } = config;
	notification.info({ message, description });
}
const obj = { success, error, warning, info };
export default obj;
