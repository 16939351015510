import {
	action,
	Action,
	Thunk,
	thunk,
	ThunkOn,
	thunkOn,
	ActionOn,
	actionOn,
} from 'easy-peasy';
import { set as lodashSet } from 'lodash-es';

// types
import {
	JobMetadata,
	JobsRequestFilterSortModel,
	JobsRequestPaginationModel,
	PageElasticJobs,
	UserJobActionModel
} from 'types/career-services';
import {
	Filters,
	ElasticMappedJobs as MappedJobs,
	ElasticMappedJob as MappedJob,
	ElasticMappedJobUpdateInput as MappedJobUpdateInput,
	LabelValuePair
} from 'types/custom';

import api from './../service';
import { StoreModel } from '../../index';
import { DEFAULT_QUERY_PAYLOAD, FILTERS_DATA } from './data';
import { JobFiltersKeysEnum, jobsConstants, JobType } from 'constants/index';
import { ArrayToObj } from 'utils/mapper';
export interface AllJobsStore {
	// ********** Data ***************
	data: PageElasticJobs | null;
	filterSchemas: Filters;
	queryPayload: JobsRequestFilterSortModel;
	mappedJobs: MappedJobs | null;
	loading: boolean;
	recentSearches: LabelValuePair<string>[];

	// ********** Actions ***************
	reset: Action<AllJobsStore>;
	setData: Action<AllJobsStore, PageElasticJobs>;
	resetData: Action<AllJobsStore>;
	setQueryPayload: Action<AllJobsStore, JobsRequestFilterSortModel>;
	resetQueryPayload: Action<AllJobsStore>;
	updateFilters: Action<AllJobsStore, { key: string; value: any }>;
	setMappedJobs: Action<AllJobsStore, MappedJobs>;
	deleteMappedJob: Action<AllJobsStore, { jobId: number }>;
	resetMappedJobs: Action<AllJobsStore>;
	setSortBy: Action<AllJobsStore, string>;
	resetSortBy: Action<AllJobsStore>;
	setSearchKeywords: Action<AllJobsStore, string[]>;
	resetSearchKeywords: Action<AllJobsStore>;
	setPagination: Action<AllJobsStore, JobsRequestPaginationModel>;
	resetPagination: Action<AllJobsStore>;
	toggleLoading: Action<AllJobsStore, boolean>;
	setRecentSearches: Action<AllJobsStore, LabelValuePair<string>[]>;
	updateMappedJob: Action<AllJobsStore, MappedJobUpdateInput>;
	// ********** Thunks ***************
	fetchAllJobs: Thunk<AllJobsStore, undefined>;
	fetchRecentSearches: Thunk<AllJobsStore, undefined>;
	fetchJobsMetaAndActions: Thunk<AllJobsStore, { jobIds: number[] }>;

	// ********** Listeners ***************
	onUserReset: ThunkOn<AllJobsStore, any, StoreModel>;
	onSetData: ThunkOn<AllJobsStore, any, StoreModel>;
	onSetFilterOptions: ActionOn<AllJobsStore, StoreModel>;
	// onSetTopLocations: ActionOn<AllJobsStore, StoreModel>;
	onUpdateBookmarkSuccess: ThunkOn<AllJobsStore, any, StoreModel>;
	onUpdateRelevanceSuccess: ThunkOn<AllJobsStore, any, StoreModel>;
}

const allJobs: AllJobsStore = {
	// ********** Data ***************
	data: null,
	filterSchemas: FILTERS_DATA,
	queryPayload: DEFAULT_QUERY_PAYLOAD,
	mappedJobs: null,
	loading: false,
	recentSearches: [],
	// ********** Actions ***************
	reset: action((state, payload) => {
		state.data = null;
		state.queryPayload = DEFAULT_QUERY_PAYLOAD;
		state.mappedJobs = null;
		state.loading = false;
		state.recentSearches = [];
	}),
	setData: action((state, payload) => {
		state.mappedJobs = null; // to reset mappedJobs to show latest request jobs and not previous requested jobs
		state.data = payload;
	}),
	resetData: action((state, payload) => {
		state.data = null;
	}),
	setQueryPayload: action((state, payload) => {
		state.queryPayload = payload;
	}),
	resetQueryPayload: action((state, payload) => {
		// const queryPayload = state.queryPayload;
		// queryPayload.filters = DEFAULT_QUERY_PAYLOAD.filters;
		// queryPayload.pagination = DEFAULT_QUERY_PAYLOAD.pagination;
		state.queryPayload = DEFAULT_QUERY_PAYLOAD;
	}),
	updateFilters: action((state, payload) => {
		const { key, value } = payload;
		let filters: any = state.queryPayload.filters;
		lodashSet(filters, key, value);
		const pagination = DEFAULT_QUERY_PAYLOAD.pagination;
		state.queryPayload = { ...state.queryPayload, pagination, filters };
	}),
	setSortBy: action((state, payload) => {
		state.queryPayload.pagination = DEFAULT_QUERY_PAYLOAD.pagination;
		state.queryPayload.sortBy = payload;
	}),
	resetSortBy: action((state, payload) => {
		state.queryPayload.pagination = DEFAULT_QUERY_PAYLOAD.pagination;
		state.queryPayload.sortBy = DEFAULT_QUERY_PAYLOAD.sortBy;
	}),
	setSearchKeywords: action((state, payload) => {
		state.queryPayload.searchKeywords = payload;
	}),
	resetSearchKeywords: action((state, payload) => {
		state.queryPayload.searchKeywords = DEFAULT_QUERY_PAYLOAD.searchKeywords;
	}),
	setPagination: action((state, payload) => {
		state.queryPayload.pagination = payload;
	}),
	resetPagination: action((state, payload) => {
		state.queryPayload.pagination = DEFAULT_QUERY_PAYLOAD.pagination;
	}),
	setMappedJobs: action((state, payload) => {
		state.mappedJobs = payload;
	}),
	deleteMappedJob: action((state, payload) => {
		if (state.mappedJobs) {
			state.mappedJobs = state.mappedJobs?.filter(
				(mappedJob) => mappedJob.id !== payload.jobId,
			);
		}
	}),
	resetMappedJobs: action((state, payload) => {
		state.mappedJobs = null;
	}),
	updateMappedJob: action((state, payload) => {
		const { toChange } = payload;
		const updatedMappedJobs = state.mappedJobs || [];
		const mappedJobIndex = updatedMappedJobs.findIndex(
			(mappedJob: MappedJob) => mappedJob.jobId === payload.jobId,
		);

		if (mappedJobIndex > -1) {
			toChange.forEach(({ key, value }) => {
				lodashSet(updatedMappedJobs[mappedJobIndex], key, value);
			});
			state.mappedJobs = updatedMappedJobs;
		}
	}),
	toggleLoading: action((state, payload) => {
		state.loading = payload;
	}),
	setRecentSearches: action((state, payload) => {
		state.recentSearches = payload;
	}),
	// ********** Thunks ***************
	fetchAllJobs: thunk(async (actions, payload, { getState }) => {
		// Loading Intializing
		actions.toggleLoading(true);

		const allJobsPayload = getState().queryPayload;
		const response = await api.fetchAllJobs(allJobsPayload);
		actions.setData(response);
		actions.setMappedJobs(response.content || []);
		actions.toggleLoading(false);
	}),
	fetchJobsMetaAndActions: thunk(async (actions, payload, { getState }) => {
		const { metaList, userActionList } = await api.fetchMetaAndActions(
			payload.jobIds,
		);

		// check if there are any metadata and actions to be mapped
		if (!metaList.length && !userActionList.length) {
			return;
		}

		const metaMap = ArrayToObj(metaList, 'jobId') as {
			[key: number]: JobMetadata;
		};
		const actionsMap = ArrayToObj(userActionList, 'jobId') as {
			[key: number]: UserJobActionModel;
		};

		const jobs = getState().data?.content || [];
		const mappedJobs = jobs.map((job) => {
			const jobId = job.id as number;
			// return { ...job, meta:metaMap[jobId], userAction:actionsMap[jobId] };
			return { ...job, ...metaMap[jobId], ...actionsMap[jobId] };
		});
		actions.setMappedJobs(mappedJobs);
	}),
	fetchRecentSearches: thunk(async (actions, _, { getState }) => {
		const response = await api.fetchRecentSearches();
		if (Array.isArray(response)) {
			const updatedResponse: LabelValuePair<string>[] = response.map((keyword: Array<string>) => (
				{label: keyword.toString(), value: keyword.map(word => word.replaceAll(',', jobsConstants.htmlEntityOfComma)).toString()
				}));
			actions.setRecentSearches(updatedResponse);
		}
	}),
	// ********** Listeners ***************
	onUserReset: thunkOn(
		(actions, storeActions) => storeActions.user.reset,
		async (actions, target) => {
			await actions.reset();
		},
	),
	onSetData: thunkOn(
		(actions) => actions.setData,
		async (actions, target) => {
			const jobs = target?.payload?.content || [];
			// using as keyword because we know job.id can never be undefined
			const jobIds = jobs.map((job) => job.id) as number[];
			jobIds.length && actions.fetchJobsMetaAndActions({ jobIds });
		},
	),
	onSetFilterOptions: actionOn(
		(actions, storeActions) => storeActions.jobs.setFilterOptions,
		(state, target) => {
			const { payload: filterOptions } = target;
			let updatedFilterSchema = state.filterSchemas.map((filterObj) => {
				if (filterObj.key === JobFiltersKeysEnum.Field) {
					if ('options' in filterObj) {
						let options = filterOptions?.fields || [];
						const mappedOptions: LabelValuePair<string>[] = [];
						options.forEach((option) => {
							if (option['filterKey'] && option['filterText']) {
								mappedOptions.push({
									value: option['filterKey'],
									label: option['filterText'],
								});
							}
						});
						filterObj.options = mappedOptions;
					}
				}

				else if (filterObj.key === JobFiltersKeysEnum.Location) {
					if ('options' in filterObj) {
						const mappedOptions: LabelValuePair<string>[] = [];
						filterOptions.locations?.forEach((option:any) => {
							mappedOptions.push({
								label: option.baseLocation,
								value: option.baseLocation
							})
						});
						filterObj.options = mappedOptions;
					}
				}
				return filterObj;
			});
			state.filterSchemas = updatedFilterSchema;
		},
	),
	onUpdateBookmarkSuccess: thunkOn(
		(actions, storeActions) => storeActions.jobs.updateBookmark.successType,
		async (actions, target) => {
			const { payload } = target;
			if (payload.jobType === JobType.ALL) {
				actions.updateMappedJob({
					jobId: payload.jobId,
					toChange: [{ key: 'isBookMarked', value: payload.payload.value }],
				});
			}
		},
	),
	onUpdateRelevanceSuccess: thunkOn(
		(actions, storeActions) => storeActions.jobs.updateRelevance.successType,
		async (actions, target) => {
			const { payload } = target;
			if (payload.jobType === JobType.ALL) {
				actions.updateMappedJob({
					jobId: payload.jobId,
					toChange: [
						{ key: 'relevance', value: payload.payload.relevance },
						{
							key: 'relevanceReasonId',
							value: payload.payload.relevanceReason,
						},
					],
				});
			}
		},
	),
};

export default allJobs;
