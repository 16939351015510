// export enum JobType {
//   ALL = 'AllJobs',
//   RECOMMENDED = 'RecommendedJobs',
//   APPLIED = 'AppliedJobs',
//   SAVED = 'SavedJobs',
// }
export enum JobType {
	ALL = 'all',
	APPLIED = 'applied',
	SAVED = 'saved',
	RECOMMENDED = 'recommended',
	JOBBOARD = 'jobBoard'
}
