import React, { useEffect, useRef } from 'react';
import { useStoreActions, useStoreState } from 'store';
import { useNavigate } from 'react-router-dom';

// components
import { Modal } from 'antd';
import { Auth } from '@upgrad-accounts/flows';

// constant, helpers
import { getEnv } from 'utils/env';
import {
	apiHeaders,
	LeadTouchPoints,
} from '../../constants';
import {
	getExtraFieldsForLeadDrop,
	getLeadDropPayload,
	getLeadTouchPoint,
	handleRedirection,
} from 'utils/helpers';
import { UserLeadInfo } from 'types/auth-services';
import {
	DropLeadExtraFields,
	DropLeadPayload,
} from 'types/auth-services/models/User';
import { TrackEvents } from 'services';

// styles
// import styles from'./LoginAuthFlow.module.scss';

const { AUTH_TOKEN_HEADER } = apiHeaders;

interface LoginAuthFlowProps {}

const LoginAuthFlow: React.FC<LoginAuthFlowProps> = (props) => {
	// const {} = props;
	// ********** store actions ***************
	const { loginByFlow, resetIsLoginModalOpen, dropLead, fetchUserLeadInfo } =
		useStoreActions((store) => store.user);

	// ********** store state ***************
	const { isLoginModalOpen, isLoggedInFrom, isLoggedInByFlow } = useStoreState(
		(store) => store.user,
	);
	const { queryPayload } = useStoreState((store) => store.jobs.jobBoard);
	const { jobBoardDetails, jobRedirectionDetails } = useStoreState(
		(store) => store.jobs,
	);
	// ********** variables ***************
	/* Setting up the environment variables for the Auth Flow. */
	const envConfig = {
		AUTH_BASE_URL: getEnv('authApiServerUrl'),
		CENTRAL_BASE_URL: getEnv('centralApiServerUrl'),
		AUTHTOKEN_KEY: AUTH_TOKEN_HEADER,
		MARKETING_URL: getEnv('marketingPlatformUrl'),
		device_type: 'desktop', // or 'desktop', 'mobile
	};

	/* This is the initial data that is passed to the Auth Flow. */
	const initialData = {
		switches: {
			removeEmail: 0,
		},
		additionalQuestions: {
			isVisible: false,
			isRequired: false,
			questions: [
				{
					label: 'Highest Qualification Completed',
					type: 'radio',
					options: [],
					key: 'leadEligibility',
				},
			],
		},
		// step: 'EMAIL', // to have Email show up first, default is phone number
		isCreateLead: false,
		createExternalLead: false,
	};
	// ********** local state ***************
	/* Used to track if the user has signed up or not. */
	const signUpRef = useRef<boolean>(false);
	// ********** hooks ***************
	const navigate = useNavigate();

	// ********** effects ***************
	useEffect(() => {
		if (!isLoggedInByFlow) {
			signUpRef.current = false;
		}
	}, [isLoggedInByFlow]);

	// ********** handlers ***************
	// ********** templates ***************
	/* Called once the Auth Flow is completed */
	const handleRedirectionAfterAuth = (token: string) => {
		// Set the token to the cookie as AUTH_TOKEN_COOKIE.
		let programNameByWeightage: string | undefined;
		loginByFlow(token);
		if (isLoggedInFrom) {
			programNameByWeightage = handleRedirection(
				isLoggedInFrom,
				navigate,
				jobRedirectionDetails,
				jobBoardDetails?.jobDomains,
				jobBoardDetails?.id?.toString(),
			);
		}
		isLoggedInFrom &&
			fetchUserLeadInfo().then((response: UserLeadInfo) => {
				const leadTouchPoint: LeadTouchPoints = getLeadTouchPoint(
					isLoggedInFrom,
					signUpRef.current,
				);
				TrackEvents.log('lead_submitted', {
					lead_origin: leadTouchPoint,
				});
				const extraFields: DropLeadExtraFields = getExtraFieldsForLeadDrop(
					isLoggedInFrom,
					{
						queryPayload: queryPayload,
						jobDetails: jobBoardDetails ?? undefined,
						programName: programNameByWeightage,
					},
				);

				const { utm_source, utm_campaign, utm_medium, utm_content, utm_term } =
					TrackEvents.baseProperties;
				const payload: DropLeadPayload = {
					payload: getLeadDropPayload(response, leadTouchPoint, extraFields),
					headers: {
						utm_source: utm_source || '',
						utm_medium: utm_medium || '',
						utm_campaign: utm_campaign || '',
						utm_term: utm_term || '',
						utm_content: utm_content || '',
					},
				};

				dropLead(payload);
				TrackEvents.setupProfile(response.user.name, response.user.email);
				TrackEvents.updateBaseProperties(response);
			});
	};

	/**
	 * When the user clicks the cancel button, close the modal.
	 */
	const handleCancel = () => {
		resetIsLoginModalOpen();
	};

	/**
	 * A callback function that is called after a user has successfully registered.
	 */
	const handleAfterRegistration = () => {
		signUpRef.current = true;
	};

	if (!isLoginModalOpen) return null;
	return (
		<Modal
			visible={isLoginModalOpen}
			closable
			onCancel={handleCancel}
			footer={null}
			width={800}
			centered
		>
			<Auth
				eventHandler={{
					trackEvent: 'trackEvent',
					trackView: 'trackView',
					identify: 'identify',
					// Add your own tracking functions, or leave them empty
					tracker: {
						trackEvent: () => {},
						trackView: () => {},
						identify: () => {},
					},
				}}
				handleRedirection={handleRedirectionAfterAuth}
				eventProps={{
					page_url: window.location.href,
					program_package_key: 'Jobs',
					page_title: 'Jobs',
					device_type: window.innerWidth < 768 ? 'mWeb' : 'dWeb',
					platform: window.innerWidth < 768 ? 'mWeb' : 'dWeb',
					logged_in_flag: false,
					section: 'jobs',
					actions: 'jobs',
				}} // base event props to be added to each event
				envConfig={envConfig}
				afterRegistration={handleAfterRegistration}
				initialData={initialData}
			/>
		</Modal>
	);
};
export default LoginAuthFlow;
