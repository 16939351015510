// import { createAxios } from '../../utils/custom-axios';
import axios, { AxiosResponse } from 'axios';
import { getEnv } from 'utils/env';
import {
	User,
	LoginAPIRequestPayload,
	ForgotPasswordAPIRequestPayload,
	ChangePasswordAPIRequestPayload,
	UserLeadInfo,
} from 'types/auth-services';
import { DropLeadPayload } from 'types/auth-services/models/User';

const unInterceptedAxios = axios.create();
const BASE_URL = `${getEnv('authApiServerUrl')}/apis`;
const CENTRAL_URL = `${getEnv('centralApiServerUrl')}/apis`;

const URL = {
	LOGIN: `${BASE_URL}/auth/v5/login`,
	LOGOUT: `${BASE_URL}/logout`,
	FORGOT_PASSWORD: `${BASE_URL}/v2/users/password/forgot`,
	CHANGE_PASSWORD: `${BASE_URL}/v2/users/password/change`,
	USER_LEAD_INFO: `${CENTRAL_URL}/v2/authenticate/`,
	LEAD_DROP: `${CENTRAL_URL}/v7/drop-lead`,
};

export default (() => {
	return {
		login: (userData: LoginAPIRequestPayload) =>
			unInterceptedAxios.post<AxiosResponse<User>>(URL.LOGIN, userData),
		logout: () => axios.post(URL.LOGOUT),
		forgotPassword: (payload: ForgotPasswordAPIRequestPayload) =>
			axios.post(URL.FORGOT_PASSWORD, payload),
		changePassword: (payload: ChangePasswordAPIRequestPayload) =>
			axios.post(URL.CHANGE_PASSWORD, payload),
		fetchUserLeadInfo: () => axios.get<UserLeadInfo>(URL.USER_LEAD_INFO),
		dropLead: (payload: DropLeadPayload) =>
			axios.post(URL.LEAD_DROP, payload.payload, { headers: payload.headers }),
	};
})();
