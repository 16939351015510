import { getEnv } from '../utils/env';
const env = getEnv('env');

export default Object.freeze({
	AUTH_TOKEN_COOKIE: `upgrad-auth-token.${env}`,
	COURSE_ID_COOKIE: `upgrad-career-last-active-course-id.${env}`,
	SESSION_ID_COOKIE: `upgrad-sessionId.${env}`,
	HIRATION_AUTH: `hiration-auth-token.${env}`,
	EXTERNAL_COURSE_ID: `courseId`,
	BANNER_COOKIE_KEY: `bannerCookie`,
	COHORT_NAME: 'cohort-name',
});
