import axios from 'axios';
import { getEnv } from 'utils/env';
import {
	StandardResponseCCLaunchResponseModel,
	StandardResponseObject,
	StandardResponseCCActiveResponseModel,
	StandardResponseShowTabFirst,
} from 'types/career-services';
import { ProfileData } from 'types/custom';

const BASE_URL = getEnv('careerServicesServerUrl');
const PROFILE_PREFIX_URL = `${BASE_URL}/learner/profile`;
const PROFILE_DATA_BASE_URL = getEnv('careerPythonServiceBaseURL');

const URL = {
	FLAG: `${PROFILE_PREFIX_URL}/flag`,
	FIRST_TAB: `${PROFILE_PREFIX_URL}/show-tab-first`,
	CC_LAUNCH_DATA: `${PROFILE_PREFIX_URL}/cc-launch-data`,
	CC_Active: `${PROFILE_PREFIX_URL}/flag/cc-active`,
	PROFILE_DATA: `${PROFILE_DATA_BASE_URL}/user/profile?display_type=FORM`,
	HIRATION_TOKEN: `${BASE_URL}/user/resume/builder/token`,
};

export default (() => {
	return {
		getCCLaunchData: () =>
			axios.get<StandardResponseCCLaunchResponseModel>(
				URL.CC_LAUNCH_DATA,
			),
		getHirationToken: () => axios.get<string>(URL.HIRATION_TOKEN),
		getFlags: () => axios.get<StandardResponseObject>(URL.FLAG),
		getFirstTab: () => axios.get<StandardResponseShowTabFirst>(URL.FIRST_TAB),
		getCCActive: () =>
			axios.get<StandardResponseCCActiveResponseModel>(URL.CC_Active),
		getProfileData: (courseId: string) =>
			axios.get<ProfileData>(URL.PROFILE_DATA, {
				headers: { sessionId: 'abc', course_id: courseId },
			}),
	};
})();
