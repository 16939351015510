import { transform, isEqual, isArray, isObject } from 'lodash-es';

import {
	AuthRedirectionFrom,
	JobFiltersKeysEnum,
	jobsConstants,
	leadDropConstants,
	LeadTouchPoints,
} from '../constants';
import { formatDistanceToNow, isFuture, format } from 'date-fns';
import {
	DropLeadExtraFields,
	DropLeadInput,
	LoginAPIErrorResponse,
	UserLeadInfo,
} from 'types/auth-services';
import { getEnv } from './env';
import { Filters, JobDetailsAndMeta, TypedObject } from 'types/custom';
import {
	DomainsMetadataModel,
	JobsRequestFilterSortModel,
} from 'types/career-services';
import { NavigateFunction } from 'react-router-dom';
import { TrackEvents } from 'services';

/**
 * @description Message to show index of jobs based on selected page
 * @param {number} numberOfElements - jobs returned for the selected page from API
 * @param {number} currentPage - current page number returned from API
 * @param {number} totalElements - total elements found returned from API
 * @return {string} - message to show jobs number returned in the selected page
 */
export function jobsShowingText(
	numberOfElements: number,
	currentPage: number,
	totalElements: number,
) {
	const jobsToDeductFromRange = 0;
	const rangeBase =
		(currentPage === 0 ? 0 : currentPage - 1) * jobsConstants.pageSize;
	const listStart = rangeBase + 1;
	const listEnd = rangeBase + numberOfElements - jobsToDeductFromRange;
	return `Showing ${listStart} - ${listEnd} of ${totalElements} jobs`;
}

export function getSalaryInString(
	salaryFrom: number | undefined,
	salaryTo: number | undefined,
) {
	if (salaryFrom != null && salaryTo != null) {
		return `${
			salaryFrom === salaryTo
				? jobsConstants.salaryCurrencySymbol + salaryFrom
				: jobsConstants.salaryCurrencySymbol + salaryFrom + '-' + salaryTo
		} ${jobsConstants.salaryUnit}`;
	} else if (!salaryFrom && salaryTo) {
		return `Upto ${jobsConstants.salaryCurrencySymbol}${salaryTo} ${jobsConstants.salaryUnit}`;
	} else if (!salaryTo && salaryFrom) {
		return `At least ${jobsConstants.salaryCurrencySymbol}${salaryFrom} ${jobsConstants.salaryUnit}`;
	} else {
		return 'NA';
	}
}

export function getExperienceInString(
	minExp: number | undefined,
	maxExp: number | undefined,
	extraShort: boolean = false,
) {
	if (extraShort) return `${minExp}-${maxExp} yr`;
	if (minExp && maxExp) {
		return minExp === maxExp ? `${minExp} years` : `${minExp}-${maxExp} years`;
	} else if (!minExp && maxExp) {
		return `upto ${maxExp} years`;
	} else if (minExp && !maxExp) {
		return `at least ${minExp} years`;
	} else {
		return 'NA';
	}
}

export function getApplicantsCountInString(
	applicantsCount: number,
	isMobile: boolean,
) {
	if (applicantsCount === 0) {
		return isMobile ? 'Apply first' : 'Be the first to apply';
	} else {
		return `${applicantsCount} Applied`;
	}
}

export function parseToDateFormat(
	date: Date | number,
	prefixType: string,
): string {
	if (isFuture(date)) {
		return (
			'Interview on <b>' +
			format(date, 'MMM D') +
			' at ' +
			format(date, 'h:mm A') +
			'</b>'
		);
	} else {
		if (prefixType === 'interview') {
			return 'Interviewed ' + formatDistanceToNow(date) + ' ago';
		} else {
			return 'Saved ' + formatDistanceToNow(date) + ' ago';
		}
	}
}
export function formatBytes(bytes: number, decimals: number = 0) {
	if (bytes === 0) return '0 Bytes';
	const k = 1000,
		dm = decimals <= 0 ? 0 : decimals || 2,
		sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
		i = Math.floor(Math.log(bytes) / Math.log(k));
	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function retriveErrorMessage(data: LoginAPIErrorResponse) {
	if (!data.success && data.errorList?.length) {
		return data.errorList[0].errorMessage
			? data.errorList[0].errorMessage
			: 'Login Unsuccessful';
	}
	return null;
}

/**
 * Generate a random string of a given length
 * @param {number} length - The length of the string to be generated.
 * @param [chars=0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ] - The characters to be
 * used in the random string.
 * @returns A string of random characters.
 */
export function getRandomString(
	length: number,
	chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ',
) {
	let result = '';
	for (let i = length; i > 0; --i)
		result += chars[Math.floor(Math.random() * chars.length)];
	return result;
}

function handleRedirectionByDomain(
	domains: Array<string>,
	jobRedirectionDetails: Array<DomainsMetadataModel> | null,
) {
	if (domains.length === 1 && jobRedirectionDetails) {
		const response = jobRedirectionDetails.find(
			(jobDetails) => jobDetails.domain === domains[0],
		);
		return response;
	}
	if (domains.length > 1 && jobRedirectionDetails) {
		const response = jobRedirectionDetails
			.filter(
				(jobDetails) =>
					jobDetails.domain && domains.includes(jobDetails.domain),
			)
			.sort((a, b) => (a.weight && b.weight ? a.weight - b.weight : 0));
		return response && response.length && response[0];
	}
}
export function handleRedirection(
	isLoggedInFrom: AuthRedirectionFrom,
	navigate?: NavigateFunction,
	jobRedirectionDetails?: Array<DomainsMetadataModel> | null,
	domains?: Array<string>,
	jobId?: string,
) {
	switch (isLoggedInFrom) {
		case AuthRedirectionFrom.APPLY:
			const domainByWeightage =
				domains &&
				jobRedirectionDetails &&
				handleRedirectionByDomain(domains, jobRedirectionDetails);
			TrackEvents.log('c_job_apply_click', {
				id: jobId,
				source: 'CEIPAL',
				category: 'Jobs Revamp',
				domain: domainByWeightage && domainByWeightage?.domain,
			});
			if (domainByWeightage && domainByWeightage.url) {
				window.open(
					domainByWeightage.url.concat(
						`?utm_source=cc_job_board&utm_medium=apply_job&utm_content=${domainByWeightage.domain}&job_id=${jobId}`,
					),
				);
				return domainByWeightage.programName;
			} else {
				navigate && navigate('/courses');
				break;
			}
		case AuthRedirectionFrom.BANNER:
		case AuthRedirectionFrom.COURSES:
			window.open(`${getEnv('MARKETING_URL')}`, '_self');
			break;
		default:
			return;
	}
}

export function getLeadDropPayload(
	userLeadInfo: UserLeadInfo,
	touchPoint: LeadTouchPoints,
	extraFields: DropLeadExtraFields,
) {
	const {
		user: { firstname, lastname, email, phoneNumber, city, state, country },
	} = userLeadInfo;
	const {
		jobDomain = '',
		jobTotalExperience = '',
		jobRelevantExperience = '',
		jobSalary = '',
		jobLocation = '',
	} = extraFields;
	const leadDropPayload: DropLeadInput = {
		firstname: firstname,
		lastname: lastname,
		email: email,
		phoneNumber: phoneNumber,
		course: leadDropConstants.course,
		courseList: [leadDropConstants.course],
		sendWelcomeMail: false,
		city: city,
		isDetectLocation: true,
		detectedData: {
			city: city,
			state: state,
			country: country,
		},
		leadSource: {
			platform: window.innerWidth < 768 ? 'mWeb' : 'dWeb',
			platformSection: touchPoint,
		},
		extraFields: {
			[leadDropConstants.extraFields.JOBDOMAIN]: jobDomain,
			[leadDropConstants.extraFields.JOBTOTALEXPERIENCE]: jobTotalExperience,
			[leadDropConstants.extraFields.JOBSALARY]: jobSalary,
			[leadDropConstants.extraFields.JOBLOCATION]: jobLocation,
			[leadDropConstants.extraFields.JOBRELEVANTEXPERIENCE]:
				jobRelevantExperience,
		},
	};

	return leadDropPayload;
}

export function getLeadTouchPoint(
	isLoggedInFrom: AuthRedirectionFrom,
	isSignUp: boolean,
): LeadTouchPoints {
	switch (isLoggedInFrom) {
		case AuthRedirectionFrom.APPLY:
			return isSignUp
				? LeadTouchPoints.JobApplySignup
				: LeadTouchPoints.JobApplySignin;
		case AuthRedirectionFrom.BANNER:
			return isSignUp
				? LeadTouchPoints.JobboardEndSignup
				: LeadTouchPoints.JobboardEndSignin;
		case AuthRedirectionFrom.HEADER:
		default:
			return isSignUp
				? LeadTouchPoints.JobTopSectionSignup
				: LeadTouchPoints.JobTopSectionSignin;
	}
}

export function getExtraFieldsForLeadDrop(
	isLoggedInFrom: AuthRedirectionFrom,
	payload: {
		queryPayload?: JobsRequestFilterSortModel;
		jobDetails?: JobDetailsAndMeta;
		programName?: string;
	},
): DropLeadExtraFields {
	const { queryPayload = {}, jobDetails, programName } = payload;
	switch (isLoggedInFrom) {
		case AuthRedirectionFrom.APPLY:
			return jobDetails
				? {
						jobDomain: programName ? programName : '',
						jobTotalExperience: objectToString({
							min: jobDetails.totalExpMin,
							max: jobDetails.totalExpMax,
						}),
						jobRelevantExperience: objectToString({
							min: jobDetails.relevantExpMin,
							max: jobDetails.relevantExpMax,
						}),
						jobSalary: objectToString({
							min: jobDetails.salaryFrom,
							max: jobDetails.salaryTo,
						}),
						jobLocation: jobDetails.location,
				  }
				: {};
		case AuthRedirectionFrom.BANNER:
		case AuthRedirectionFrom.HEADER:
		default:
			return {
				jobDomain: queryPayload.filters?.fields
					? queryPayload.filters?.fields.toString()
					: '',
				jobTotalExperience: queryPayload.filters?.experience?.total
					? objectToString(queryPayload.filters?.experience?.total)
					: '',
				jobRelevantExperience: queryPayload.filters?.experience?.relevant
					? objectToString(queryPayload.filters?.experience?.relevant)
					: '',
				jobSalary: queryPayload.filters?.salary
					? objectToString(queryPayload.filters?.salary?.range)
					: '',
				jobLocation: queryPayload.filters?.locations
					? queryPayload.filters?.locations.toString()
					: '',
			};
	}
}

/**
 * It takes an object and returns a new object with all the nested objects flattened
 * @param obj - The object to flatten
 * @param [prefix] - This is the prefix that will be added to the key of the nested object.
 * @returns flattened object
 * @example
 * const obj = {
 * 	name: 'varun',
 * 		meta: {
 * 			address: { country: 'India', state: 'M.P', city: 'satna' },
 * 				phone: '12345667',
 * 				alias: ['name1', 'name2'],
 * 		},
 * 	};
 * output is:-
 * { name: 'varun',
 *	'meta.address.country': 'India',
 *	'meta.address.state': 'M.P',
 *	'meta.address.city': 'satna',
 *	'meta.phone': '12345667',
 *	'meta.alias': [ 'name1', 'name2' ] }
 */
export const flattenObject = (obj: TypedObject<any>, prefix = '') => {
	const newObj = Object.keys(obj).reduce((acc: TypedObject<any>, key) => {
		const currentProp = obj[key];
		const newKey = prefix ? `${prefix}.${key}` : key;
		if (typeof currentProp === 'object' && !Array.isArray(currentProp)) {
			const nestedObj = flattenObject(currentProp, newKey);
			acc = { ...acc, ...nestedObj };
		} else {
			acc[newKey] = obj[key];
		}
		return acc;
	}, {});

	return newObj;
};

/**
 * Find difference between two objects
 * @param  {object} origObj - Source object to compare newObj against
 * @param  {object} newObj  - New object with potential changes
 * @return {object} differences
 */
export function differenceObj(
	origObj: TypedObject<any>,
	newObj: TypedObject<any>,
) {
	function changes(origObj: TypedObject<any>, newObj: TypedObject<any>) {
		let arrayIndexCounter = 0;
		return transform(newObj, function (result: TypedObject<any>, value, key) {
			if (!isEqual(value, origObj[key])) {
				let resultKey = isArray(origObj) ? arrayIndexCounter++ : key;
				result[resultKey] =
					isObject(value) && isObject(origObj[key])
						? changes(value, origObj[key])
						: value;
			}
		});
	}
	return changes(newObj, origObj);
}

export function objectToString(obj: TypedObject<any>, shouldFlatten?: boolean) {
	let finalArray = [];
	let objNew = shouldFlatten ? flattenObject(obj) : obj;

	for (const key in objNew) {
		objNew[key] && finalArray.push(`${key}=${objNew[key]}`);
	}

	return finalArray.toString();
}

export function filtersEventString(
	data: JobsRequestFilterSortModel,
	schema: Filters,
): string {
	function getLabel(key: string, childKey?: string) {
		let rangeValues = schema.find((jobFilter) => jobFilter.key === key);

		if (childKey) {
			rangeValues = rangeValues?.children?.find(
				(jobChildFilter) => jobChildFilter.key === childKey,
			);
		}

		if (rangeValues && 'min' in rangeValues && 'max' in rangeValues) {
			return [rangeValues?.min.label, rangeValues.max.label];
		}
		return ['0', '30+'];
	}

	const eventString = `easyApply-${
		data.filters?.easyApply
	},Domain-{${data.filters?.fields?.toString()}},experience_relevant:${
		data.filters?.experience?.relevant?.min ??
		getLabel(
			JobFiltersKeysEnum.Experience,
			JobFiltersKeysEnum.RelevantExperience,
		)[0]
	}to${
		data.filters?.experience?.relevant?.max ??
		getLabel(
			JobFiltersKeysEnum.Experience,
			JobFiltersKeysEnum.RelevantExperience,
		)[1]
	},experience_total:${
		data.filters?.experience?.total?.min ??
		getLabel(
			JobFiltersKeysEnum.Experience,
			JobFiltersKeysEnum.TotalExperience,
		)[0]
	}to${
		data.filters?.experience?.total?.max ??
		getLabel(
			JobFiltersKeysEnum.Experience,
			JobFiltersKeysEnum.TotalExperience,
		)[1]
	},salary:${
		data.filters?.salary?.range.min ??
		getLabel(JobFiltersKeysEnum.Salary, JobFiltersKeysEnum.Salary)[0]
	}to${
		data.filters?.salary?.range.max ??
		getLabel(JobFiltersKeysEnum.Salary, JobFiltersKeysEnum.Salary)[1]
	},location-{${data.filters?.locations?.toString()}}`;
	return eventString;
}
