import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { set as lodashSet, isEqual } from 'lodash-es';

import {
	EncodedURLParamKeys,
	DecodedURLParamKeys,
	ParamDataType,
	RedirectConstants,
} from 'constants/url-params';
import { flattenObject } from 'utils/helpers';
import { NonArrayObject, TypedObject } from 'types/custom';
import { isRedirectedFromFUE } from 'utils/isRedirectedFromFUE';

const useQueryParam = (defaultParams: NonArrayObject = {}) => {
	const [searchParams, setSearchParams] = useSearchParams();

	/* Memoizing the default params so that it doesn't have to be flattened every time the hook is called. */
	const memoizedFlatDefaultParams = useMemo(
		() => flattenObject(defaultParams),
		[defaultParams],
	);

	// ********** parsing params ***************
	/**
	 * It takes a value and a type, and returns the value cast to the type
	 * @param {string} value - The value of the parameter.
	 * @param {string} type - The type of the parameter.
	 * @returns type casted value
	 */
	const typeCast = (value: string, type: string) => {
		if (!value) return value;

		switch (type) {
			case 'number':
				return Number(value);
			case 'boolean':
				return JSON.parse(value);
			default:
				return value;
		}
	};

	/* Parsing the URL search params and returning an object with the decoded keys and type casted values. */
	const params = useMemo(() => {
		let obj = {};

		for (const key of searchParams.keys()) {
			let value;
			const dataType = ParamDataType[key];
			const decodedKey = DecodedURLParamKeys[key] || key;

			if (dataType === 'array') {
				value = searchParams.getAll(key);
			} else {
				value = typeCast(searchParams.get(key) || '', dataType);
				value = key === 'page' ? value - 1 : value;
			}

			lodashSet(obj, decodedKey, value);
		}

		return obj;
	}, [searchParams]);

	// ********** setting params ***************

	/**
	 * It clears the URL search params
	 */
	const clearAllParams = () => {
		const queryParams: any = {};
		if (isRedirectedFromFUE()) {
			queryParams[RedirectConstants.pageSource] =
				RedirectConstants.freeUserExperiencePageKey;
		}
		setSearchParams(queryParams);
	};

	/**
	 * It takes an object, flattens it, compares it to the default params, and then sets the URL search params
	 * @param obj - TypedObject<any>
	 */
	const setParamObj = (obj: TypedObject<any>) => {
		const flatObj = flattenObject(obj);
		const encodedDiff: TypedObject<any> = {};

		for (let [key, value] of Object.entries(flatObj)) {
			if (
				!isEqual(value, memoizedFlatDefaultParams[key]) &&
				value !== undefined &&
				value !== null
			) {
				const encodedKey = EncodedURLParamKeys[key];
				// check for pagination
				value = encodedKey === 'page' ? value + 1 : value;
				if (encodedKey) {
					encodedDiff[encodedKey] = value;
				}
			}
		}

		setSearchParams(encodedDiff);
	};

	return {
		params,
		setParamObj,
		clearAllParams,
	};
};

export default useQueryParam;
