import { Filters } from 'types/custom';
import { FilerTypeEnum, JobFiltersKeysEnum } from 'constants/filters';
import { JobsRequestFilterSortModel } from 'types/career-services';
import { jobSortOptions, DeviceType } from '../../../constants';

export const DEFAULT_QUERY_PAYLOAD: JobsRequestFilterSortModel = {
	sortBy: 'recent',
	searchKeywords: [],
	pagination: { pageNumber: 0, pageSize: 10 },
	filters: {
		appliedJobsSource: [],
	},
};

export const FILTERS_DATA: Filters = [
	{
		key: JobFiltersKeysEnum.SortBy,
		label: 'Sort By',
		isExpanded: false,
		type: FilerTypeEnum.RADIO,
		options: jobSortOptions.options,
		defaultValue: jobSortOptions.defaultMode,
		headerClassName: 'collapseHeader',
		devices: [DeviceType.MOBILE, DeviceType.TABLET],
	},
	{
		key: JobFiltersKeysEnum.AppliedJobsSource,
		label: 'Job Source',
		isExpanded: true,
		type: FilerTypeEnum.CHECKBOX,
		options: [
			{ value: 'upgrad', label: 'upGrad Easy Apply jobs' },
			{ value: 'external', label: 'Externally applied jobs' },
		],
		defaultValue: [],
		headerClassName: 'collapseHeader',
		devices: [DeviceType.DESKTOP, DeviceType.MOBILE, DeviceType.TABLET],
	},
];
