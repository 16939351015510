import { action, Action, thunk, Thunk, thunkOn, ThunkOn } from 'easy-peasy';
import { set as lodashSet } from 'lodash-es';

import {
	BookMarkModel,
	JobFiltersModel,
	RelevanceModel,
	HasAppliedModel,
	DomainsMetadataModel,
} from 'types/career-services';
import {
	JobDetailsAndMeta,
	MappedJobDetails,
	MappedJobDetailsUpdate,
	ResumeList,
	TypedObject,
	ScrollPosition,
} from 'types/custom';

import allJobs, { AllJobsStore } from './all-jobs';
import jobBoard, { JobBoardStore } from './job-board';
import appliedJobs, { AppliedJobsStore } from './applied-jobs';
import recommendedJobs, { RecommendedJobsStore } from './recommended-jobs';
import savedJobs, { SavedJobsStore } from './saved-jobs';
import { StoreModel } from 'store';

import api from './service';
import { JobType } from '../../constants';
import { TrackEvents } from 'services';
import { DEFAULT_SCROLL_POSITIONS } from './data';

export interface JobsStore {
	// ********** Sub Stores ***************
	all: AllJobsStore;
	recommended: RecommendedJobsStore;
	saved: SavedJobsStore;
	applied: AppliedJobsStore;
	jobBoard: JobBoardStore;
	// ********** Data ***************
	externalOpenedJobs: TypedObject<boolean>;
	filterOptions: JobFiltersModel | null;
	jobDetails: MappedJobDetails | null;
	jobBoardDetails: JobDetailsAndMeta | null;
	resumeList: ResumeList | null;
	scrollPosition: ScrollPosition;
	jobRedirectionDetails: Array<DomainsMetadataModel> | null;
	// ********** Actions ***************
	reset: Action<JobsStore>;
	setFilterOptions: Action<JobsStore, JobFiltersModel>;
	setJobDetails: Action<JobsStore, MappedJobDetails>;
	resetJobDetails: Action<JobsStore>;
	setJobBoardDetails: Action<JobsStore, JobDetailsAndMeta>;
	setResumeList: Action<JobsStore, ResumeList>;
	updateJobDetails: Action<JobsStore, MappedJobDetailsUpdate>;
	addToExternalOpenedJobs: Action<JobsStore, { jobId: number }>;
	removeFromExternalOpenedJobs: Action<JobsStore, { jobId: number }>;
	setScrollPosition: Action<JobsStore, Partial<ScrollPosition>>;
	resetScrollPosition: Action<JobsStore>;
	setJobRedirectionDetails: Action<JobsStore, DomainsMetadataModel[]>;
	// ********** Thunks ***************
	getAllFilterOptions: Thunk<JobsStore, undefined>;
	getJobDetails: Thunk<JobsStore, { jobId: string }>;
	getJobBoardDetails: Thunk<JobsStore, { jobId: string }>;
	getResumes: Thunk<JobsStore, undefined>;
	applyJob: Thunk<JobsStore, { jobId: number; resumeId: number }>;
	updateRelevance: Thunk<
		JobsStore,
		{ jobId: number; jobType: JobType; payload: RelevanceModel }
	>;
	updateBookmark: Thunk<
		JobsStore,
		{ jobId: number; jobType: JobType; payload: BookMarkModel }
	>;
	fetchClickedOutside: Thunk<
		AllJobsStore,
		{ jobId: number; payload: HasAppliedModel }
	>;
	fetchAppliedOutside: Thunk<
		AllJobsStore,
		{ jobId: number; payload: HasAppliedModel }
	>;
	getJobRedirectionDetails: Thunk<JobsStore, undefined>;
	// ********** Listeners ***************
	onSetCCLaunchData: ThunkOn<JobsStore, any, StoreModel>;
	onUserReset: ThunkOn<JobsStore, any, StoreModel>;
}

const jobs: JobsStore = {
	// ********** Sub Stores ***************
	all: allJobs,
	recommended: recommendedJobs,
	saved: savedJobs,
	applied: appliedJobs,
	jobBoard: jobBoard,
	// ********** Data ***************
	jobDetails: null,
	jobBoardDetails: null,
	resumeList: null,
	filterOptions: null,
	externalOpenedJobs: {},
	scrollPosition: DEFAULT_SCROLL_POSITIONS,
	jobRedirectionDetails: null,
	// ********** Actions ***************
	reset: action((state, payload) => {
		state.jobDetails = null;
		state.jobBoardDetails = null;
		state.resumeList = null;
		state.filterOptions = null;
		state.externalOpenedJobs = {};
		state.scrollPosition = DEFAULT_SCROLL_POSITIONS;
	}),
	setJobDetails: action((state, payload) => {
		state.jobDetails = payload;
	}),
	resetJobDetails: action((state, payload) => {
		state.jobDetails = null;
	}),
	setJobBoardDetails: action((state, payload) => {
		state.jobBoardDetails = payload;
	}),
	updateJobDetails: action((state, payload) => {
		const { toChange, jobId } = payload;
		const jobDetails = state.jobDetails;
		if (jobDetails?.id === jobId) {
			toChange.forEach(({ key, value }) => {
				lodashSet(jobDetails, key, value);
			});
			state.jobDetails = jobDetails;
		}
	}),
	setResumeList: action((state, payload) => {
		state.resumeList = payload;
	}),
	setFilterOptions: action((state, payload) => {
		state.filterOptions = payload;
	}),
	addToExternalOpenedJobs: action((state, payload) => {
		const updatedExternalOpenedJobs = {
			...state.externalOpenedJobs,
			[payload.jobId]: true,
		};
		state.externalOpenedJobs = updatedExternalOpenedJobs;
	}),
	removeFromExternalOpenedJobs: action((state, payload) => {
		const updatedExternalOpenedJobs = {
			...state.externalOpenedJobs,
		};
		delete updatedExternalOpenedJobs[payload.jobId];
		state.externalOpenedJobs = updatedExternalOpenedJobs;
	}),
	setScrollPosition: action((state, payload) => {
		state.scrollPosition = { ...state.scrollPosition, ...payload };
	}),
	resetScrollPosition: action((state) => {
		state.scrollPosition = DEFAULT_SCROLL_POSITIONS;
	}),
	setJobRedirectionDetails: action((state, payload) => {
		state.jobRedirectionDetails = payload;
	}),
	// ********** Thunks ***************
	getJobDetails: thunk(async (actions, payload, { fail }) => {
		const { jobId } = payload;
		try {
			const jobDetails = await api.fetchCompleteJobDetails(+jobId);
			actions.setJobDetails(jobDetails);
		} catch (error) {
			fail(error);
		}
	}),
	getJobBoardDetails: thunk(async (actions, payload, { fail }) => {
		const { jobId } = payload;
		try {
			const jobBoardDetails = await api.fetchJobDetailsAndMeta(+jobId);
			actions.setJobBoardDetails(jobBoardDetails);
		} catch (error) {
			fail(error);
		}
	}),
	getResumes: thunk(async (actions, payload) => {
		const response = await api.fetchResumeList();
		actions.setResumeList(response.resumes);
	}),
	applyJob: thunk(async (actions, payload) => {
		const { jobId, resumeId } = payload;
		try {
			await api.applyJob(jobId, { resumeId });
			actions.updateJobDetails({
				jobId,
				toChange: [{ key: 'applicationStatus', value: 'Applied' }],
			});
		} catch (err: any) {
			TrackEvents.log('c_job_apply_error', {
				job_id: jobId,
				job_type: 'CEIPAL',
				error_message: err?.message,
			});
		}
	}),
	getAllFilterOptions: thunk(async (actions, payload) => {
		const { filterList, topLocationsList } = await api.fetchAllFilterOptions();
		filterList.locations = topLocationsList;
		actions.setFilterOptions(filterList);
	}),
	updateBookmark: thunk(async (actions, { jobId, payload }, { fail }) => {
		try {
			const response = await api.updateBookmark(jobId, payload);

			if (response.errorCode) {
				return fail(response.message);
			}

			actions.updateJobDetails({
				jobId,
				toChange: [{ key: 'userAction.isBookMarked', value: !!payload.value }],
			});
			return response;
		} catch (err) {
			console.log('update Bookmark error', err);
		}
	}),
	updateRelevance: thunk(async (actions, { jobId, payload }, { fail }) => {
		try {
			const response = await api.updateRelevance(jobId, payload);

			if (response.errorCode) {
				return fail(response.message);
			}

			actions.updateJobDetails({
				jobId,
				toChange: [
					{ key: 'userAction.relevance', value: payload.relevance },
					{
						key: 'userAction.relevanceReasonId',
						value: payload.relevanceReason,
					},
				],
			});
			return response;
		} catch (err) {
			console.log('update Relevance error', err);
		}
	}),
	fetchClickedOutside: thunk(
		async (actions, { jobId, payload }, { getState }) => {
			await api.fetchClickedOutside(jobId, payload);
		},
	),
	fetchAppliedOutside: thunk(
		async (actions, { jobId, payload }, { getState }) => {
			await api.fetchAppliedOutside(jobId, payload);
		},
	),

	getJobRedirectionDetails: thunk(async (actions, payload) => {
		const response = await api.fetchJobRedirectionDetails();
		response &&
			response.data &&
			actions.setJobRedirectionDetails(response.data);
	}),
	// ********** Listeners ***************
	onSetCCLaunchData: thunkOn(
		(actions, storeActions) => storeActions.learnerController.setLaunchData,
		async (actions, target) => {
			await actions.getAllFilterOptions();
			await actions.getResumes();
		},
	),
	onUserReset: thunkOn(
		(actions, storeActions) => storeActions.user.reset,
		async (actions, target) => {
			await actions.reset();
		},
	),
};

export default jobs;
