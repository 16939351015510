// import { createAxios } from '../../utils/custom-axios';
import axios from 'axios';
import {
	JobsRequestFilterSortModel,
	UserJobActionModel,
	UserActionRequestFilterModel,
	PageElasticJobs,
	PageJobApplications,
	JobMetadata,
	BookMarkModel,
	RelevanceModel,
	JobFiltersModel,
	HasAppliedModel,
	JobDetails,
	JobApplicationStatus,
	UserJobApplyModel,
	StandardApiResponseListDomainsMetadataModel,
} from 'types/career-services';
import {
	DefaultResponse,
	JobDetailsAndMeta,
	MappedJobDetails,
	ResumeResponse,
	TopLocationsModel,
} from 'types/custom';

import { LongToast } from 'services';
import notificationMsg from './notifications-messages';
import { getEnv } from 'utils/env';
import shortToast from 'services/short-toast';

const BASE_URL = getEnv('careerServicesServerUrl');
const JOB_PREFIX_URL = `${BASE_URL}/learner/jobs`;
const RESUME_BASE_URL = getEnv('careerPythonServiceBaseURL');

const URL = {
	ACTION: `${JOB_PREFIX_URL}/user/action/`,
	APPLICATION_STATUS: `${JOB_PREFIX_URL}/application/status/`,
	APPLIED_JOBS: `${JOB_PREFIX_URL}/application/`,
	APPLIED_OUTSIDE: `${JOB_PREFIX_URL}/user/actions/has_applied/:jobID`,
	APPLY_JOB: `${JOB_PREFIX_URL}/apply-job/:jobID`,
	BOOKMARK: `${JOB_PREFIX_URL}/user/actions/bookmark/:jobID`,
	CLICKED_OUTSIDE: `${JOB_PREFIX_URL}/user/actions/clicked_outside/:jobID`,
	FILTER_OPTION_VALUES: `${JOB_PREFIX_URL}/filter/`,
	TOP_LOCATIONS: `${JOB_PREFIX_URL}/top-locations`,
	JOBS: `${JOB_PREFIX_URL}/v2/`,
	JOB_METADATA: `${JOB_PREFIX_URL}/metadata/`,
	JOB_DETAILS: `${JOB_PREFIX_URL}/details/:jobID`,
	JOB_DESCRIPTION: 'user/job/:jobID',
	RECENT_SEARCHES: `${JOB_PREFIX_URL}/recent/searches/`,
	RECOMMENDED_JOBS: `${JOB_PREFIX_URL}/recommended-jobs/`,
	JOB_REDIRECTION: `${BASE_URL}/career/config/domains-metadata`,
	RELEVANCE: `${JOB_PREFIX_URL}/user/actions/relevance/:jobID`,
	RESUME_LIST: `${RESUME_BASE_URL}/user/resume`,
	SAVED_JOBS: `${JOB_PREFIX_URL}/saved/`,
	USER_APPLICATIONS: '/user/application/:applicationID',
};

export default (() => {
	// const api = createAxios({
	//   baseURL: getEnv('careerServicesServerUrl'),
	//   name: 'learn-controller',
	// });

	const api = {
		fetchJobDetails: (jobId: number) =>
			axios.get<JobDetails>(
				URL.JOB_DETAILS.replace(':jobID', jobId.toString()),
			),
		fetchAllJobs: (payload: JobsRequestFilterSortModel) =>
			axios.post<PageElasticJobs>(URL.JOBS, payload).catch((err) => {
				LongToast.error({
					description: notificationMsg.JOBS.ERROR_MSG,
				});
				throw new Error(err);
			}),
		fetchAppliedJobs: (payload: JobsRequestFilterSortModel) =>
			axios
				.post<PageJobApplications>(URL.APPLIED_JOBS, payload)
				.catch((err) => {
					LongToast.error({
						description: notificationMsg.APPLIED_JOBS.ERROR_MSG,
					});
					throw new Error(err);
				}),
		fetchRecommendedJobs: (payload: JobsRequestFilterSortModel) =>
			axios
				.post<PageElasticJobs>(URL.RECOMMENDED_JOBS, payload)
				.catch((err) => {
					LongToast.error({
						description: notificationMsg.RECOMMENDED_JOBS.ERROR_MSG,
					});
					throw new Error(err);
				}),
		fetchSavedJobs: (payload: JobsRequestFilterSortModel) =>
			axios.post<PageElasticJobs>(URL.SAVED_JOBS, payload).catch((err) => {
				LongToast.error({
					description: notificationMsg.SAVED_JOBS.ERROR_MSG,
				});
				throw new Error(err);
			}),
		fetchJobsMetaData: (payload: UserActionRequestFilterModel) =>
			axios.post<JobMetadata[]>(URL.JOB_METADATA, payload),
		fetchUserAction: (payload: UserActionRequestFilterModel) =>
			axios.post<Array<UserJobActionModel>>(URL.ACTION, payload),
		fetchApplicationStatus: (payload: UserActionRequestFilterModel) =>
			axios
				.post<Array<JobApplicationStatus>>(URL.APPLICATION_STATUS, payload)
				.catch((err) => {
					LongToast.error({
						description: notificationMsg.APPLICATION_STATUS.ERROR_MSG,
					});
					throw new Error(err);
				}),
		fetchRecentSearches: () =>
			axios.get<Array<Array<string>>>(URL.RECENT_SEARCHES),
		updateBookmark: (jobId: number, payload: BookMarkModel) =>
			axios
				.post<DefaultResponse>(
					URL.BOOKMARK.replace(':jobID', jobId.toString()),
					payload,
				)
				.catch((err) => {
					LongToast.error({
						description: payload.value
							? notificationMsg.BOOKMARK.ERROR_MSG_BOOKMARK
							: notificationMsg.BOOKMARK.ERROR_MSG_NOT_BOOKMARK,
					});
					throw err;
				}),
		updateRelevance: (jobId: number, payload: RelevanceModel) =>
			axios
				.post<DefaultResponse>(
					URL.RELEVANCE.replace(':jobID', jobId.toString()),
					payload,
				)
				.then((res) => {
					if (!res.errorCode) {
						shortToast.success(notificationMsg.RELEVANCE.SUCCESS_MSG);
					}
					return res;
				})
				.catch((err) => {
					LongToast.error({
						description: notificationMsg.RELEVANCE.ERROR_MSG,
					});
					throw err;
				}),
		fetchFiltersOptions: () =>
			axios.get<JobFiltersModel>(URL.FILTER_OPTION_VALUES),
		fetchTopLocations: () => axios.get<TopLocationsModel>(URL.TOP_LOCATIONS),
		fetchClickedOutside: (jobId: number, payload: HasAppliedModel) =>
			axios.post<DefaultResponse>(
				URL.CLICKED_OUTSIDE.replace(':jobID', jobId.toString()),
				payload,
			),
		fetchAppliedOutside: (jobId: number, payload: HasAppliedModel) =>
			axios.post<DefaultResponse>(
				URL.APPLIED_OUTSIDE.replace(':jobID', jobId.toString()),
				payload,
			),
		fetchResumeList: () =>
			axios
				.get<ResumeResponse>(URL.RESUME_LIST, {
					headers: { sessionId: 'abc' },
				})
				.catch((err) => {
					LongToast.error({
						description: notificationMsg.RESUME_LIST.ERROR_MSG,
					});
					throw new Error(err);
				}),
		applyJob: (jobId: number, payload: UserJobApplyModel) =>
			axios
				.post<string>(
					URL.APPLY_JOB.replace(':jobID', jobId.toString()),
					payload,
				)
				.then((res) => {
					shortToast.success(notificationMsg.APPLY_JOB.SUCCESS_MSG);
					return res;
				})
				.catch((err) => {
					LongToast.error({
						description: notificationMsg.APPLY_JOB.ERROR_MSG,
					});
					throw new Error(err);
				}),

		fetchJobRedirectionDetails: () =>
			axios.get<StandardApiResponseListDomainsMetadataModel>(
				URL.JOB_REDIRECTION,
			),
		// ********** Custom ***************
		fetchCompleteJobDetails: async (
			jobId: number,
		): Promise<MappedJobDetails> => {
			try {
				const response = await Promise.all([
					api.fetchJobDetails(jobId),
					api.fetchJobsMetaData({ ids: [jobId] }),
					api.fetchUserAction({ ids: [jobId] }),
				]);
				const [jobDetails, [meta], [actions]] = response;
				return { ...jobDetails, meta: meta, userAction: actions };
			} catch (err) {
				LongToast.error({
					description: notificationMsg.JOB_DETAILS.ERROR_MSG,
				});
				throw new Error(err as string);
			}
		},
		fetchJobDetailsAndMeta: async (
			jobId: number,
		): Promise<JobDetailsAndMeta> => {
			try {
				const response = await Promise.all([
					api.fetchJobDetails(jobId),
					api.fetchJobsMetaData({ ids: [jobId] }),
				]);
				const [jobDetails, [meta]] = response;
				return { ...jobDetails, meta: meta };
			} catch (err) {
				LongToast.error({
					description: notificationMsg.JOB_DETAILS.ERROR_MSG,
				});
				throw new Error(err as string);
			}
		},
		fetchMetaAndActions: async (jobIds: Array<number>) => {
			const [metaResponse, actionsResponse] = await Promise.allSettled([
				api.fetchJobsMetaData({ ids: jobIds }),
				api.fetchUserAction({ ids: jobIds }),
			]);
			const meta =
				metaResponse.status === 'fulfilled' ? metaResponse.value : [];
			const actions =
				actionsResponse.status === 'fulfilled' ? actionsResponse.value : [];
			return { metaList: meta, userActionList: actions };
		},
		fetchMetaAndStatuses: async (
			jobIds: number[],
			applicationIds: number[],
		) => {
			const [metaResponse, statusResponse] = await Promise.allSettled([
				api.fetchJobsMetaData({ ids: jobIds }),
				api.fetchApplicationStatus({ ids: applicationIds }),
			]);
			const metaList =
				metaResponse.status === 'fulfilled' ? metaResponse.value : [];
			const statusList =
				statusResponse.status === 'fulfilled' ? statusResponse.value : [];
			return { metaList, statusList };
		},

		fetchAllFilterOptions: async () => {
			const [filterResponse, topLocationsResponse] = await Promise.allSettled([
				api.fetchFiltersOptions(),
				api.fetchTopLocations(),
			]);
			const filterList: any =
				filterResponse.status === 'fulfilled' ? filterResponse.value : [];
			const topLocationsList: TopLocationsModel =
				topLocationsResponse.status === 'fulfilled'
					? topLocationsResponse.value
					: [];
			return { filterList, topLocationsList };
		},
	};

	return api;
})();
