import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { Provider, ErrorBoundary } from '@rollbar/react';
import { StoreProvider } from 'easy-peasy';

import App from './App';
import Spinner from 'components/Spinner';
import ErrorDisplay from 'components/ErrorDisplay';

import store from './store';
import { rollbar } from 'services';

ReactDOM.render(
	<Suspense fallback={<Spinner />}>
		<React.StrictMode>
			<Provider instance={rollbar}>
				<StoreProvider store={store}>
					<ErrorBoundary
						errorMessage="Error in React render"
						fallbackUI={ErrorDisplay}
					>
						<BrowserRouter>
							<App />
						</BrowserRouter>
					</ErrorBoundary>
				</StoreProvider>
			</Provider>
		</React.StrictMode>
	</Suspense>,
	document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
