import { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

// components

import 'services/axios';

// types
import { LocationProps } from 'types/custom';

// styles
import './App.less';
import 'styles/index.scss';
import '@upgrad-accounts/flows/dist/main.css';
import '@upgrad-accounts/components/dist/main.css';

import { Layout } from 'antd';
import { useOnMount } from 'hooks';
import { TrackEvents, Logger } from 'services';
import lazy from 'utils/lazy-load';
import LoginAuthFlow from 'pages/LoginAuthFlow/LoginAuthFlow';
import GoBackToDashboardCta from 'components/GoBackToDashboardCta';
import { RedirectConstants } from 'constants/index';

const NotFound = lazy(() => import('./pages/NotFound'));
const JobBoard = lazy(() => import('pages/JobBoard'));
const CohortBlocker = lazy(() => import('pages/CohortBlocker'));
const JobBoardDetails = lazy(() => import('./pages/JobBoardDetails'));

const logger = new Logger({ fileName: 'App' });

function App() {
	useOnMount(() => {
		TrackEvents.triggerIntialEvents();
		TrackEvents.log('c_entered_career_center', {
			category: 'General',
		});
	});

	logger.log('inside APP ======', process.env);

	const location = useLocation() as LocationProps;
	const navigate = useNavigate();

	useEffect(() => {
		const psSearchParam = new URLSearchParams(location.state?.from?.search).get(
			RedirectConstants.pageSource,
		);
		const isFUEPresent = psSearchParam === 'fue';
		if (isFUEPresent) {
			navigate(`${location.pathname}${location.search ? '&' : '?'}ps=fue`, {
				replace: true,
			});
		}
	}, [location]);

	return (
		<>
			<Layout style={{ minHeight: '130vh' }}>
				<LoginAuthFlow />
				<Routes>
					<Route path="/" element={<JobBoard />}></Route>
					<Route path="/details/:jobId" element={<JobBoardDetails />} />
					<Route path="/courses" element={<CohortBlocker />} />
					<Route path="*" element={<NotFound />} />
				</Routes>
			</Layout>
			<GoBackToDashboardCta />
		</>
	);
}

export default App;
