import React from 'react';
import { Result } from 'antd';

// import styles from'./index.module.scss';

interface ErrorDisplayProps {}
const ErrorDisplay: React.FC<ErrorDisplayProps> = (props) => {
	// const {} = props;
	return (
		<Result
			status="500"
			title="Sorry"
			subTitle="Something went wrong, please refresh."
		/>
	);
};
export default ErrorDisplay;
